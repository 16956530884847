const themeTextStyles = {
  "size-xs": {
    fontSize: "xs",
    lineHeight: "xs",
  },
  "size-sm": {
    fontSize: "sm",
    lineHeight: "sm",
  },
  "size-md": {
    fontSize: "md",
    lineHeight: "md",
  },
  "size-lg": {
    fontSize: "lg",
    lineHeight: "lg",
  },
  "size-xl": {
    fontSize: "xl",
    lineHeight: "xl",
  },
  "size-2xl": {
    fontSize: "2xl",
    lineHeight: "2xl",
  },
  "size-3xl": {
    fontSize: "3xl",
    lineHeight: "3xl",
  },
  "size-4xl": {
    fontSize: "4xl",
    lineHeight: "4xl",
  },
  "size-5xl": {
    fontSize: "5xl",
    lineHeight: "5xl",
  },
  "size-6xl": {
    fontSize: "6xl",
    lineHeight: "6xl",
  },
  h1: {
    fontSize: "2xl",
    lineHeight: "2xl",
    fontFamily: "heading",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "xl",
    lineHeight: "xl",
    fontWeight: "medium",
  },
  h3: {
    fontSize: "lg",
    lineHeight: "lg",
    fontFamily: "heading",
    fontWeight: "bold",
  },
  menuBadge: {
    borderRadius: "10px",
    padding: "2px 6px",
    fontSize: "10px",
    lineHeight: "normal",
    bg: "purpleMenu.500",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "18px",
  },
  inputLabelElement: {
    pointerEvents: "none",
    color: "grayMid.500",
    textTransform: "uppercase",
  },
  generalLabel: {
    color: "grayMid.500",
    fontWeight: "normal",
  },
  generalValue: {
    color: "grayDark.500",
    fontWeight: "medium",
  },
};

export default themeTextStyles;
