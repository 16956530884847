import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAuthParams } from "../auth/types";
import { API_BASE_URL } from "../config";
import { IAccountDetails, ISession, IUpdateAccountDetails } from "./types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["AccountDetails", "Session"],
  endpoints: (builder) => ({
    passwordReset: builder.mutation<{ password_reset_url: string }, { ticket: string }>({
      query: (payload) => {
        const { ticket } = payload;
        return {
          url: "/v1/password_reset",
          method: "POST",
          body: { ticket },
        };
      },
    }),
    getSession: builder.query<ISession, IAuthParams>({
      query: ({ access_token }) => {
        return {
          url: "/v1/session",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Session"],
    }),
    getAccountDetails: builder.query<IAccountDetails, IAuthParams>({
      query: ({ access_token }) => {
        return {
          url: "/v1/account",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["AccountDetails"],
    }),
    updateAccountDetails: builder.mutation<{ updated: boolean }, IUpdateAccountDetails>({
      query: ({ access_token, ...payload }) => {
        return {
          url: "/v1/account",
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["AccountDetails"],
    }),
  }),
});

export const {
  useGetSessionQuery,
  useGetAccountDetailsQuery,
  usePasswordResetMutation,
  useUpdateAccountDetailsMutation,
} = accountApi;
