import React, { useState } from "react";

type Elements = "bank_id_auth_modal" | "required_actions_modal" | "invalid_inputs";

type ControlProps = {
  invalidInputs: Record<string, boolean>;
  elementState: Record<Elements, boolean> | undefined;
  setElementState: (state: Record<string, boolean>) => void;
  setInputState: (state: Record<string, boolean>) => void;
};

export const RemoteControlContext = React.createContext<ControlProps>({
  invalidInputs: {},
  elementState: undefined,
  setElementState: () => {},
  setInputState: () => {},
});
type Props = {
  children: JSX.Element;
};
export default function RemoteControlProvider({ children }: Props) {
  const [elementState, setState] = useState<Record<string, boolean>>({
    bank_id_auth_modal: false,
  });

  const [invalidInputs, setInvalidInputs] = useState<Record<string, boolean>>({});
  const setElementState = (newState: Record<string, boolean>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const setInputState = (inputState: Record<string, boolean>) => {
    setInvalidInputs((prevState) => ({ ...prevState, ...inputState }));
  };

  const value = {
    invalidInputs,
    elementState,
    setElementState,
    setInputState,
  };
  return <RemoteControlContext.Provider value={value}>{children}</RemoteControlContext.Provider>;
}
