import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { RUN_LOCALLY } from "../config";
import LocallyProtectedComponent from "./LocallyProtectedComponent";
import { AccessTokenContext as LocalAccessTokenContext } from "./LocallyProtectedComponent";
type Props = {
  children: JSX.Element;
};

let AccessTokenContext = React.createContext("");

const ProtectedComponent = ({ children }: Props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [access_token, setAccessToken] = useState("");

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        const redirectTarget = `${window.location.pathname}${window.location.search}`;
        loginWithRedirect({ appState: { target: redirectTarget } });
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, setAccessToken, loginWithRedirect]);

  if (!access_token) return null;

  return <AccessTokenContext.Provider value={access_token}>{children}</AccessTokenContext.Provider>;
};

let defaultExportedComponent = ProtectedComponent;
if (RUN_LOCALLY) {
  defaultExportedComponent = LocallyProtectedComponent;
  AccessTokenContext = LocalAccessTokenContext;
}
export { AccessTokenContext };

export default defaultExportedComponent;
