import { DealerRequiredAction } from "../../types";
import ContactSupport_v1_0 from "./ContactSupport_v1_0";
import DefaultTemplate from "../DefaultTemplate";

const ContactSupport = (action: DealerRequiredAction) => {
  switch (action.schema_version) {
    case "1.0":
      return ContactSupport_v1_0;
  }
  return DefaultTemplate;
};

export default ContactSupport;
