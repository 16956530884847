import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { ICreateTransactionFilePayload, ICreateTransactionFileResponse } from "./types";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    createTransactionFile: builder.mutation<ICreateTransactionFileResponse, ICreateTransactionFilePayload>({
      query: ({ access_token, account_id, ...queryParams }) => {
        return {
          url: "v1/account_invoice_report",
          method: "POST",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
  }),
});

export const { useCreateTransactionFileMutation } = transactionsApi;
