import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, Center, Text, Image, Flex } from "@chakra-ui/react";
import { usePasswordResetMutation } from "../account/service";
import Icon from "../shared/Icon";
import Loader from "../shared/Loader";
import useTranslate from "../locale/hooks/useTranslate";
import { IT_SUPPORT_EMAIL } from "../config";

const PasswordReset = () => {
  const translate = useTranslate();
  const [searchParams] = useSearchParams();

  const [hasError, setHasError] = useState<boolean>(false);

  const ticket = searchParams.get("ticket") || "";

  const [passwordReset] = usePasswordResetMutation();

  useEffect(() => {
    if (ticket) {
      passwordReset({ ticket })
        .unwrap()
        .then((response) => {
          window.location.href = response.password_reset_url;
        })
        .catch((error) => {
          setHasError(true);
        });
    }
  }, [ticket, passwordReset]);

  return (
    <Flex
      p="8"
      m="auto"
      w="100%"
      h="100vh"
      flexDirection="column"
      alignItems="center"
    >
      <Image src="/images/logo-gray.svg" h="10" alt="Handlarfinans logo" />
      {!hasError && (
        <Center flexDirection="column" textAlign="center" mt="auto" mb="auto">
          <Loader
            color="purpleMain.500"
            thickness="4px"
            speed="0.9s"
            emptyColor="purple.200"
            size="xl"
          />
          <Text
            textStyle={["size-5xl"]}
            fontFamily="heading"
            fontWeight="light"
          >
            {translate("password_reset.wait")}
          </Text>
        </Center>
      )}
      {hasError && (
        <>
          <Center flexDirection="column" textAlign="center" mt="auto" mb="auto">
            <Icon name="report" size="164px" weight="200" color="redMain.500" />
            <Text
              textStyle={["size-5xl"]}
              fontFamily="heading"
              fontWeight="light"
            >
              {translate("password_reset.invalid_invitation")}
            </Text>
            <Text
              textStyle={["size-2xl"]}
              color="grayMid.500"
              mt="6"
              maxW="600px"
            >
              {translate("password_reset.contact")}&nbsp;
              <Link
                href={`mailto:${IT_SUPPORT_EMAIL}`}
                target="_blank"
                color="orangeMain.500"
              >
                {IT_SUPPORT_EMAIL}
              </Link>
            </Text>
          </Center>
        </>
      )}
    </Flex>
  );
};

export default PasswordReset;
