import React, { useContext } from "react";
import { useGetSessionQuery } from "../account/service";
import { FeatureFlagEnum } from "../account/types";
import { AccessTokenContext } from "../auth/ProtectedComponent";

type Props = {
  children: React.ReactNode;
  features: FeatureFlagEnum[];
};
const FeatureGate = ({ children, features }: Props) => {
  const access_token = useContext(AccessTokenContext);

  const { data, isError } = useGetSessionQuery({ access_token }, { skip: !access_token });

  const featureAllowed = data?.feature_flags.some(featureFlag => features.includes(featureFlag));

  if (isError) {
    return null;
  }

  return <>{featureAllowed ? children : null}</>;
};

export default FeatureGate;
