import { Box, Center, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useGetLastKycApplication } from "../../../kyc/hooks/useGetKYCPendingApplication";
import useTranslate from "../../../locale/hooks/useTranslate";
import Icon from "../../../shared/Icon";
import { DealerRequiredAction } from "../../types";
import ContactSupportLink from "../ContactSupportLink";

type Props = {
  action: DealerRequiredAction;
};
export default function KycRequired_v1_0({ action }: Props) {
  const { kycApplicationId, isLoading } = useGetLastKycApplication("pending");
  const translate = useTranslate();
  const navigate = useNavigate();
  const goTo = () => {
    navigate(`/kyc/application/${kycApplicationId}`);
  };
  if (isLoading) {
    return null;
  }
  return (
    <>
      {kycApplicationId && (
        <Flex onClick={goTo} cursor="pointer" gap="2" align="center">
          <Center borderRadius="full" bgColor="reds.100" p="2" flex="0 0 auto" minW="0">
            <Icon name="contact_support" color="reds.700" size="32px" />
          </Center>

          <Box fontWeight="medium">{translate("required_actions.action_types.kyc_required")}</Box>
        </Flex>
      )}
      {!kycApplicationId && <ContactSupportLink />}
    </>
  );
}
