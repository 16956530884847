import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RUN_LOCALLY } from "../../config";
import LocalAuthCallbackPage from "./LocalAuthCallbackPage";
import { accountApi } from "../../account/service";

const AuthCallbackPage = () => {
  const navigate = useNavigate();
  const { handleRedirectCallback, getAccessTokenSilently } = useAuth0();
  const [fetchSession] = accountApi.useLazyGetSessionQuery();

  useEffect(() => {
    const getCallbackState = async () => {
      try {
        const callbackParams = await handleRedirectCallback();
        if (callbackParams?.appState?.target) {
          navigate(callbackParams?.appState?.target);
          return;
        }
        // const redirectPath = callbackParams?.appState?.target ? callbackParams.appState.target : "/dashboard";
        const token = await getAccessTokenSilently();
        const session = await fetchSession({
          access_token: token,
        }).unwrap();

        if (session.is_onboarded) {
          navigate("/dashboard");
          // navigate("/onboarding");

          return;
        } else {
          navigate("/onboarding");
          return;
        }
      } catch (e: any) {
        console.log("error", e);
      }
    };
    getCallbackState();
  }, [fetchSession, getAccessTokenSilently, handleRedirectCallback, navigate]);

  return null;
};

let defaultExportedComponent = AuthCallbackPage;
if (RUN_LOCALLY) {
  defaultExportedComponent = LocalAuthCallbackPage;
}
export default defaultExportedComponent;
