const checkbox = {
  baseStyle: {
    control: {
      w: 4,
      h: 4,
      borderRadius: "2px",
      bg: "#fff",
      borderColor: "purple.200",
      _checked: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _indeterminate: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _disabled: {
        borderColor: "gray.300",
      },
    },
  },
  variants: {
    rounded: {
      control: {
        border: "1px solid",
        borderRadius: "full",
      },
      container: {
        p: 2,
        border: "1px",
        borderColor: "grayLight.500",
        borderRadius: "lg",
        cursor: "pointer",
        _checked: {
          cursor: "pointer",
          borderColor: "purple.800",
          bg: "purple.200",
          color: "purple.900",
        },
      },
    },
  },
};

export default checkbox;
