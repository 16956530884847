import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IFetchDealerLocationPayload,
  IFetchDealerLocationResponse,
  IGetDealerAccountPayload,
  IGetDealerAccountResponse,
  IGetDealerLocationsPayload,
  IGetDealerLocationsResponse,
  IUpdateDealerAccountPayload,
  IUpdateDealerAccountResponse,
} from "./types";

export const dealerAccountApi = createApi({
  reducerPath: "dealerAccountApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["DealerAccount", "Location"],
  endpoints: (builder) => ({
    getDealerAccount: builder.query<IGetDealerAccountResponse, IGetDealerAccountPayload>({
      query: ({ access_token }) => {
        return {
          url: `/v1/dealer_account`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["DealerAccount"],
    }),
    fetchDealerLocations: builder.query<IGetDealerLocationsResponse, IGetDealerLocationsPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/dealer_locations",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Location"],
    }),
    fetchDealerLocation: builder.query<IFetchDealerLocationResponse, IFetchDealerLocationPayload>({
      query: ({ access_token, location_id }) => {
        return {
          url: `/v1/dealer_location/${location_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Location"],
    }),
    updateDealerAccount: builder.mutation<IUpdateDealerAccountResponse, IUpdateDealerAccountPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: "/v1/dealer_account",
          method: "PATCH",
          body: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["DealerAccount"],
    }),
  }),
});

export const {
  useGetDealerAccountQuery,
  useFetchDealerLocationsQuery,
  useFetchDealerLocationQuery,
  useUpdateDealerAccountMutation,
} = dealerAccountApi;
