import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  useToast,
  Center,
  Text,
  Divider,
  Image,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo } from "react";
import QRCode from "react-qr-code";
import { accountApi } from "../account/service";
import { AccessTokenContext } from "./ProtectedComponent";
import { authApi, useFetchBankIdAuthStatusQuery, useInitializeBankIdAuthMutation } from "./service";
import useTranslate from "../locale/hooks/useTranslate";
import Loader from "../shared/Loader";
import { RemoteControlContext } from "../shared/RemoteControlProvider";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import { BankIdHintForPendingOrders, IBankIdAuthStatus } from "./types";
import useUrlState from "../hooks/useUrlState";

type Props = {
  isModalOpen: boolean;
  handleCloseModal: (state: boolean) => void;
};

export const BankIdAuthModal = ({ isModalOpen, handleCloseModal }: Props) => {
  const navigate = useNavigate();
  const [urlState, setUrlState] = useUrlState();
  const { orderId } = urlState;
  const access_token = useContext(AccessTokenContext);
  const translate = useTranslate();
  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();

  const hintMessageMapping: Record<BankIdHintForPendingOrders, string> = {
    outstanding_transaction: translate("bank_id_auth.hint_messages.RFA1"),
    no_client: translate("bank_id_auth.hint_messages.RFA1"),
    started: translate("bank_id_auth.hint_messages.RFA15"),
    user_mrtd: translate("bank_id_auth.hint_messages.RFA23"),
    user_sign: translate("bank_id_auth.hint_messages.RFA9"),
    unknown_hint_code: translate("bank_id_auth.hint_messages.RFA21"),
    user_call_confirm: "",
  };

  const closeModal = () => {
    handleCloseModal(false);
    setUrlState({});
  };

  const [initBankIdAuth, { data, isLoading }] = useInitializeBankIdAuthMutation();
  const orderID = orderId || data?.order_id || null;

  const { data: authStatus, isError } = useFetchBankIdAuthStatusQuery(
    {
      access_token,
      order_id: orderID,
    },
    { skip: !isModalOpen || !orderID, pollingInterval: 1000 }
  );

  const qrData = authStatus?.qr_data || data?.qr_data;
  const hint = authStatus?.hint;

  const appUrl = useMemo(() => {
    if (data) {
      const { token } = data;
      const redirectUrl = encodeURIComponent(`${BASE_URL}${location.pathname}?orderId=${data.order_id}`);
      return `bankid:///?autostarttoken=${token}&redirect=${redirectUrl}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location.pathname]);

  const invalidateTags = () => {
    dispatch(authApi.util.invalidateTags(["AuthorizationAction"]));
    dispatch(accountApi.util.invalidateTags(["Session"]));
  };

  useEffect(() => {
    if (authStatus?.status === IBankIdAuthStatus.COMPLETE) {
      toast({
        title: translate("bank_id_auth.success"),
        status: "success",
      });
      invalidateTags();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus?.status]);

  useEffect(() => {
    if (isError) {
      toast({
        title: translate("bank_id_auth.error"),
        status: "error",
      });
      invalidateTags();
      closeModal();
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isModalOpen && !isLoading && !orderId) {
      initBankIdAuth({ access_token })
        .unwrap()
        .then((response) => {
          response.order_id && setUrlState({ orderId: response.order_id });
        })
        .catch((error) => {
          const { message } = error;
          toast({
            title: message || translate("bank_id_auth.error"),
            status: "error",
          });
          closeModal();
          navigate("/dashboard");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, orderId]);

  return (
    <Box>
      <Modal isOpen={isModalOpen} onClose={closeModal} isCentered size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translate("bank_id_auth.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading && (
              <Center flexDir="column" gap="4">
                <Loader />
              </Center>
            )}
            {qrData && !isLoading && (
              <Center flexDir="column" gap="2">
                <Image src="/images/bank-id.svg" alt="BankID logo" maxH="16" />
                <QRCode value={qrData} />
                <Divider />
              </Center>
            )}
            {hint && (
              <Text textStyle="size-sm" color="gray.500" mb="4">
                {hintMessageMapping[hint]}
              </Text>
            )}
            {appUrl && <Link to={appUrl}>{translate("bank_id_auth.open_app")}</Link>}
          </ModalBody>
          <ModalFooter>
            <Button ml="4" onClick={closeModal}>
              {translate("placeholders.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export const RemoteControlBankIdAuthModal = () => {
  const { elementState, setElementState } = useContext(RemoteControlContext);
  const isVisible = elementState?.["bank_id_auth_modal"] || false;
  const closeModal = () => {
    setElementState({ bank_id_auth_modal: false });
  };
  return <BankIdAuthModal isModalOpen={isVisible} handleCloseModal={closeModal} />;
};
