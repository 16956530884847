import { Box, Center, Flex, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import useTranslate from "../locale/hooks/useTranslate";
import Icon from "../shared/Icon";
import Loader from "../shared/Loader";
import NoItems from "../shared/NoItems";
import RequiredAction from "./RequiredAction";
import { useFetchRequiredActionsQuery } from "./service";

type Props = {
  showWarning?: boolean;
};

export default function RequiredActions({ showWarning = false }: Props) {
  const access_token = useContext(AccessTokenContext);
  const translate = useTranslate();

  const { data, isLoading } = useFetchRequiredActionsQuery({ access_token });
  const requiredActions = data?.actions || [];

  return (
    <Box>
      {isLoading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!isLoading && requiredActions.length === 0 && <NoItems message={translate("required_actions.no_actions")} />}
      {!isLoading && requiredActions.length > 0 && (
        <Box>
          {showWarning && (
            <Flex gap="1" pb="4" px="4" mx="-4" mb="4" borderBottom="1px solid" borderColor="gray.300">
              <Icon name="warning" size="48px" color="reds.700" />
              <Box flex="1 1 30%">
                <Text textStyle="size-xl" fontWeight="medium">
                  {requiredActions.length > 1 &&
                    translate("required_actions.title_plural", { count: requiredActions.length.toString() })}
                  {requiredActions.length === 1 && translate("required_actions.title")}
                </Text>
                <Text color="gray.500">{translate("required_actions.description")}</Text>
              </Box>
            </Flex>
          )}

          <VStack
            divider={<StackDivider borderColor="gray.300" />}
            spacing="4"
            align="stretch"
            maxH={{ base: "initial", md: "sm" }}
            overflow={{ base: "initial", md: "auto" }}>
            {requiredActions.map((action, index) => (
              <RequiredAction key={index} action={action} />
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
}
