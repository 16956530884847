import { ActionRenderer } from "../types";
import ContactSupport from "./ContactSupport";
import CreditLimitExceeded from "./CreditLimitExceeded";
import OwnershipTransferOutstanding from "./OwnershipTransferOutstanding";
import PaymentOutstanding from "./PaymentOutstanding";
import KycRequired from "./KycRequired";

export const RequiredActionsMapping = new Map<string, ActionRenderer>([
  ["ownership_transfer_outstanding", OwnershipTransferOutstanding],
  ["payment_outstanding", PaymentOutstanding],
  ["credit_limit_exceeded", CreditLimitExceeded],
  ["contact_support", ContactSupport],
  ["kyc_required", KycRequired],
]);
