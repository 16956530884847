import { useContext } from "react";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { useListApplicationsQuery } from "../service";

export function useGetLastKycApplication(status?: string) {
  const access_token = useContext(AccessTokenContext);

  const { data, isLoading } = useListApplicationsQuery({
    access_token,
    limit: 1,
    status,
  });

  const kycApplicationId = data?.kyc_applications[0]?.kyc_application_id || null;
  const kycApplicationStatus = data?.kyc_applications[0]?.status || null;

  return { kycApplicationId, kycApplicationStatus, isLoading };
}
