const tooltip = {
  baseStyle: {
    color: "purpleMain.500",
    fontSize: "sm",
    lineHeight: "sm",
    bg: "purple.200 !important",
    borderRadius: "0",
    boxShadow: "0",
    px: "4",
    py: "3",
    maxW: "200px",
    fontWeight: "normal",
    _arrowSize: {
        width: "32px",
    },
  },  
};

export default tooltip;