const badge = {
  baseStyle: {
    fontSize: "sm",
    lineHeight: "base",
    fontWeight: "semibold",
    borderRadius: "lg",
    padding: "8px 16px",
  },  
  variants: {
    reminder: {
      bg: "purple.300",
      color: "purple.800",
    },
    notification: {
      bg: "greens.100",
      color: "greens.700",
    },
    warning: {
      bg: "orange.200",
      color: "reds.700",
    },
    done: {
      bg: "grayLight.500",
      color: "grayDark.500",
    },
    status: {
      bg: "purple.200",
      color: "purple.800",
    },
  }
};

export default badge;