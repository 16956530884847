const themeTypography = {
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "38px",
    "5xl": "48px",
    "6xl": "64px",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
    xs: "18px",
    sm: "20px",
    md: "24px",
    lg: "28px",
    xl: "30px",
    "2xl": "36px",
    "3xl": "44px",
    "4xl": "56px",
    "5xl": "72px",
    "6xl": "88px",
  },
  fontWeights: {
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  }
}

export default themeTypography;