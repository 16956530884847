const tabs = {
  parts: ["root", "tab", "tablist", "tabpanels", "tabpanel"],
  variants: {
    pills: {
      tab: {
        fontSize: "md",
        lineHeight: "md",
        color: "purple.800",
        bg: "purple.200",
        borderRadius: "20px",

        _hover: {
          bg: "purple.300",
        },

        _active: {
          bg: "purple.400",
        },

        _disabled: {
          color: "gray.500",
          bg: "gray.400",
          _hover: {
            color: "gray.500",
            bg: "gray.400",
          },
        },

        _selected: {
          color: "white",
          bg: "purple.800",
        },
      },
      tablist: {
        gap: "4",
      },
      tabpanels: {
        mt: "6",
      },
      tabpanel: {
        p: "0",
      },
    },
    "with-icons": {
      tab: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "2",
        p: "0",
        w: "160px",
        h: "120px",
        border: "1px",
        borderColor: "gray.300",
        bg: "white",
        color: "gray.600",
        fontWeight: "light",

        _hover: {
          borderColor: "purple.500",
        },

        _selected: {
          bg: "purple.200",
          borderColor: "purple.800",
          fontWeight: "medium",
        },

        _disabled: {
          color: "gray.500",
          bg: "disabled.500",
          borderColor: "gray.300",

          _hover: {
            color: "gray.500",
            bg: "disabled.500",
            borderColor: "gray.300",
          },
        },
      },
      tablist: {
        gap: "8",
      },
    },
    line: {
      tablist: {
        borderColor: "gray.300",
        borderBottom: "1px solid",
      },
      tab: {
        fontSize: "md",
        lineHeight: "md",
        fontWeight: "medium",
        color: "gray.500",
        borderBottom: "1px solid",
        px: "0",
        mr: "6",
        mb: "-1px",
        _hover: {},
        _active: {},
        _selected: {
          color: "purple.800",
        },
      },
    },
    lineAlt: {
      tablist: {
        borderBottom: "1px solid",
        borderColor: "gray.300",
      },
      tab: {
        fontSize: "xs",
        lineHeight: "xs",
        fontWeight: "semibold",
        textTransform: "uppercase",
        color: "gray.500",
        borderBottom: "1px solid",
        borderColor: "gray.300",
        px: "0",
        py: "1",
        mr: "6",
        mb: "-1px",
        _hover: {},
        _active: {},
        _selected: {
          color: "orange.700",
          borderColor: "orange.700",
        },
      },
      tabpanel: {
        px: "0",
      },
    },
  },
  defaultProps: {
    variant: "pills",
  },
};

export default tabs;
