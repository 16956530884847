import { DealerRequiredAction } from "../../types";
import DefaultTemplate from "../DefaultTemplate";
import PaymentOutstanding_v1_0 from "./PaymentOutstanding_v1_0";

const PaymentOutstanding = (action: DealerRequiredAction) => {
  switch (action.schema_version) {
    case "1.0":
      return PaymentOutstanding_v1_0;
  }
  return DefaultTemplate;
};

export default PaymentOutstanding;
