import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IFetchBankIdAuthStatusPayload,
  IFetchBankIdAuthStatusResponse,
  IGetActionAuthorizationPayload,
  IGetActionAuthorizationResponse,
  InitializeBankIdAuthPayload,
  InitializeBankIdAuthResponse,
  IPostTokenPayload,
} from "./types";

import { LOCAL_AUTH_API_BASE_URL } from "../config";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["AuthorizationAction"],
  endpoints: (builder) => ({
    initializeBankIdAuth: builder.mutation<InitializeBankIdAuthResponse, InitializeBankIdAuthPayload>({
      query: (payload) => {
        const { access_token } = payload;
        return {
          url: "/v1/integration/bankid/auth",
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    fetchBankIdAuthStatus: builder.query<IFetchBankIdAuthStatusResponse, IFetchBankIdAuthStatusPayload>({
      query: (payload) => {
        const { access_token, order_id } = payload;
        return {
          url: `/v1/integration/bankid/collect/${order_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    getActionAuthorization: builder.query<IGetActionAuthorizationResponse, IGetActionAuthorizationPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: `/v1/authorization`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["AuthorizationAction"],
    }),
  }),
});

export const localAuthApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: LOCAL_AUTH_API_BASE_URL }),
  endpoints: (builder) => ({
    fetchToken: builder.mutation<{ access_token: string; token_type: string }, IPostTokenPayload>({
      query: ({ code, grant_type, code_verifier }) => {
        const params = new URLSearchParams();
        params.append("code", code);
        params.append("grant_type", grant_type);
        params.append("code_verifier", code_verifier);
        return {
          url: `/oauth/token`,
          method: "POST",
          body: params.toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          formData: true,
        };
      },
    }),
  }),
});

export const { useFetchTokenMutation } = localAuthApi;

export const { useInitializeBankIdAuthMutation, useFetchBankIdAuthStatusQuery, useGetActionAuthorizationQuery } =
  authApi;
