import React from "react";
import { Spinner, SpinnerProps } from "@chakra-ui/react";

type Props = SpinnerProps;

const Loader = (props: Props) => {
  return <Spinner color="purpleMain.500" thickness="3px" speed="0.9s" emptyColor="purple.200" {...props} />;
};

export default Loader;
