const textarea = {
  baseStyle: {
  },
  sizes: {
    md: {
      minHeight: "100px",
      borderRadius: "0",
      padding: "12px",
    },
  },
  variants: {
    outline: {
      bg: "#fff",
      borderColor: "grayLight.500",
      _hover: {
        borderColor: "purple.500",
      },
      _focus: {
        borderColor: "purple.600",
        outline: "none",
      },
      _focusVisible: {
        borderColor: "purple.600",
        outline: "none",
        boxShadow: "none",
      },
    },
  },
}

export default textarea;