import { IAuthParams } from "../auth/types";

export enum SystemMessageSeverity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

type IMessageText = {
  language_id: string;
  text: string;
};

type ISystemMessage = {
  id: string;
  message: string;
  severity: SystemMessageSeverity;
  texts: IMessageText[];
};

interface IGetSystemMessagesPayload extends IAuthParams {}

interface IGetSystemMessagesResponse {
  messages: ISystemMessage[];
}

export type { ISystemMessage, IGetSystemMessagesPayload, IGetSystemMessagesResponse, IMessageText };
