import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IDomainEntitySearchResults, IDomainEntitySearchPayload } from "./types";

export const domainEntitySearchApi = createApi({
  reducerPath: "domainEntitySearchApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getSearchResults: builder.query<IDomainEntitySearchResults, IDomainEntitySearchPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: "/v1/search",
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
  }),
});

export const { useGetSearchResultsQuery } = domainEntitySearchApi;
