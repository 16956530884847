import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import RemoteControlProvider from "./RemoteControlProvider";
import { RemoteControlBankIdAuthModal } from "../auth/BankIdAuthModal";
import { RemoteControlRequiredActionsModal } from "../requiredActions/RequiredActionsModal";
import Icon from "./Icon";
import SystemMessages from "../systemMessages/SystemMessages";

type Props = {
  children: JSX.Element;
  isOnboarding?: boolean;
};

const Layout = ({ children, isOnboarding = false }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargeScreen] = useMediaQuery("(min-width: 992px)", { fallback: true });

  return (
    <RemoteControlProvider>
      <Flex h="100%" flexDir="column">
        <SystemMessages />
        <Flex flex="1 1 auto" minH="0" position="relative">
          {!isOnboarding && (
            <>
              {!isLargeScreen && (
                <Box position="absolute" top="4" left="4" zIndex="banner">
                  <IconButton
                    aria-label="Open sidebar"
                    variant="linkWhite"
                    _hover={{ textDecoration: "none" }}
                    icon={<Icon name="menu" />}
                    onClick={onOpen}
                  />
                </Box>
              )}
              <Drawer
                isOpen={!isLargeScreen ? isOpen : false}
                placement="left"
                onClose={onClose}
                variant="sidebarDrawer">
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton zIndex="2" color="white" />
                  <DrawerBody p="0">
                    <Sidebar />
                  </DrawerBody>
                </DrawerContent>
              </Drawer>

              <Box display={["none", null, null, "block"]}>
                <Sidebar />
              </Box>
            </>
          )}

          <Flex flex="1" flexDirection="column" height="auto" overflow="auto">
            {children}
          </Flex>

          <RemoteControlBankIdAuthModal />
          <RemoteControlRequiredActionsModal />
        </Flex>
      </Flex>
    </RemoteControlProvider>
  );
};

export default Layout;
