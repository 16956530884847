import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateAccountLegalEntityPayload,
  ICreateAccountLegalEntityResponse,
  ICreateOwnLegalEntityPayload,
  ICreateOwnLegalEntityResponse,
  ICreateUserIdentityVerificationPayload,
  ICreateUserIdentityVerificationResponse,
  IFetchUserIdentityVerificationPayload,
  IFetchUserIdentityVerificationResponse,
  IGetOnboardingOrgsPayload,
  IGetOnboardingOrgsResponse,
  IGetOnboardingStatePayload,
  IGetOnboardingStateResponse,
} from "./types";

export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Onboarding"],
  endpoints: (builder) => ({
    fetchUserIdentityVerificationStatus: builder.query<
      IFetchUserIdentityVerificationResponse,
      IFetchUserIdentityVerificationPayload
    >({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/identity/status",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Onboarding"],
    }),
    initiateIdentityVerification: builder.mutation<
      ICreateUserIdentityVerificationResponse,
      ICreateUserIdentityVerificationPayload
    >({
      query: ({ access_token, ...payload }) => {
        return {
          url: "/v1/identity/verification",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      // invalidatesTags: ["Onboarding"],
    }),
    getOnboardingState: builder.query<IGetOnboardingStateResponse, IGetOnboardingStatePayload>({
      query: ({ access_token }) => {
        return {
          url: "/v1/onboarding/state",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Onboarding"],
    }),
    getOnboardingOrgs: builder.query<IGetOnboardingOrgsResponse, IGetOnboardingOrgsPayload>({
      query: ({ access_token }) => {
        return {
          url: "/v1/onboarding/orgs",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Onboarding"],
    }),
    //TODO: check the payload and payload types
    createOwnLegalEntity: builder.mutation<ICreateOwnLegalEntityResponse, ICreateOwnLegalEntityPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/onboarding/own_legal_entity`,
          method: "POST",
          // body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Onboarding"],
    }),
    createAccountLegalEntity: builder.mutation<ICreateAccountLegalEntityResponse, ICreateAccountLegalEntityPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/onboarding/account_legal_entity`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Onboarding"],
    }),
  }),
});

export const {
  useInitiateIdentityVerificationMutation,
  useFetchUserIdentityVerificationStatusQuery,
  useGetOnboardingStateQuery,
  useGetOnboardingOrgsQuery,
  useCreateOwnLegalEntityMutation,
  useCreateAccountLegalEntityMutation,
} = onboardingApi;
