import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateInventoryVehiclePayload,
  ICreateInventoryVehicleResponse,
  ICreateTransactionPayload,
  ICreateTransactionResponse,
  IDeleteTransactionPayload,
  IDeleteTransactionResponse,
  IFetchFinanciersPayload,
  IFetchFinanciersResponse,
  IFetchInventoryVehiclePayload,
  IFetchInventoryVehicleResponse,
  IFetchTransactionsVehiclePayload,
  IFetchTransactionsVehicleResponse,
  IFetchTransactionTotalsPayload,
  IFetchTransactionTotalsResponse,
  IGetInventoryPayload,
  IGetInventoryResponse,
  IUpdateInventoryVehiclePayload,
  IUpdateInventoryVehicleResponse,
  IUpdateTransactionPayload,
  IUpdateTransactionResponse,
} from "./types";

export const inventoryVehicleApi = createApi({
  reducerPath: "inventoryVehicleApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["InventoryVehicle", "Financiers"],
  endpoints: (builder) => ({
    getInventoryVehicles: builder.query<IGetInventoryResponse, IGetInventoryPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: `/v1/inventory_vehicle`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["InventoryVehicle"],
    }),
    createInventoryVehicle: builder.mutation<ICreateInventoryVehicleResponse, ICreateInventoryVehiclePayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/inventory_vehicle`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["InventoryVehicle"],
    }),
    getInventoryVehicle: builder.query<IFetchInventoryVehicleResponse, IFetchInventoryVehiclePayload>({
      query: ({ access_token, inventory_vehicle_id }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["InventoryVehicle"],
    }),
    updateInventoryVehicle: builder.mutation<IUpdateInventoryVehicleResponse, IUpdateInventoryVehiclePayload>({
      query: ({ access_token, inventory_vehicle_id, ...payload }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["InventoryVehicle"],
    }),
    deleteTransaction: builder.mutation<IDeleteTransactionResponse, IDeleteTransactionPayload>({
      query: ({ access_token, inventory_vehicle_id, transaction_id }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}/financial_transaction/${transaction_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["InventoryVehicle"],
    }),
    createTransaction: builder.mutation<ICreateTransactionResponse, ICreateTransactionPayload>({
      query: ({ access_token, inventory_vehicle_id, ...payload }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}/financial_transaction`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["InventoryVehicle"],
    }),
    updateTransaction: builder.mutation<IUpdateTransactionResponse, IUpdateTransactionPayload>({
      query: ({ access_token, inventory_vehicle_id, ...payload }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}/financial_transaction`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["InventoryVehicle"],
    }),
    fetchTransactions: builder.query<IFetchTransactionsVehicleResponse, IFetchTransactionsVehiclePayload>({
      query: ({ access_token, inventory_vehicle_id }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}/financial_transaction`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["InventoryVehicle"],
    }),
    fetchTransactionTotals: builder.query<IFetchTransactionTotalsResponse, IFetchTransactionTotalsPayload>({
      query: ({ access_token, inventory_vehicle_id, ...params }) => {
        return {
          url: `/v1/inventory_vehicle/${inventory_vehicle_id}/financial_transaction_totals`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["InventoryVehicle"],
    }),
    fetchFinanciers: builder.query<IFetchFinanciersResponse, IFetchFinanciersPayload>({
      query: ({ access_token }) => {
        return {
          url: "/v1/financiers",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Financiers"],
    }),
  }),
});

export const {
  useGetInventoryVehiclesQuery,
  useCreateInventoryVehicleMutation,
  useGetInventoryVehicleQuery,
  useUpdateInventoryVehicleMutation,
  useFetchTransactionsQuery,
  useUpdateTransactionMutation,
  useCreateTransactionMutation,
  useDeleteTransactionMutation,
  useFetchTransactionTotalsQuery,
  useFetchFinanciersQuery,
} = inventoryVehicleApi;
