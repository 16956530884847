import { Box, Center, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useTranslate from "../../../locale/hooks/useTranslate";
import Icon from "../../../shared/Icon";
import { DealerRequiredAction } from "../../types";
type Props = {
  action: DealerRequiredAction;
};
export default function PaymentOutstanding({ action }: Props) {
  const translate = useTranslate();
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/deals-active?order_by=due_at&direction=asc");
  };
  return (
    <Flex gap="2" align="center" onClick={goTo} cursor="pointer">
      <Center borderRadius="full" bgColor="reds.100" p="2" flex="0 0 auto" minW="0">
        <Icon name="payments" color="reds.700" size="32px" />
      </Center>
      <Box fontWeight="medium">{translate(`required_actions.action_types.${action.action_type}`)}</Box>
    </Flex>
  );
}
