import { useContext, useEffect, useMemo, useState } from "react";
import { Alert, AlertIcon, AlertDescription, Flex, CloseButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import { useGetSystemMessagesQuery } from "./service";
import { IMessageText, ISystemMessage, SystemMessageSeverity } from "./types";

const SystemMessages = () => {
  const access_token = useContext(AccessTokenContext);
  const { i18n } = useTranslation();
  const { data } = useGetSystemMessagesQuery({ access_token });

  const [visibleMessages, setVisibleMessages] = useState<ISystemMessage[]>([]);

  useEffect(() => {
    if (data) {
      setVisibleMessages(data.messages);
    }
  }, [data]);

  const currentLanguage = useMemo(() => {
    const language = i18n.language.toUpperCase();
    if (language === "SV") return "SE";
    return language;
  }, [i18n.language]);

  const getMessage = (texts: IMessageText[]) => {
    const message = texts.find((text) => text.language_id === currentLanguage);
    if (message?.text && message.text.length > 0) return message;
    const EN_message = texts.find((text) => text.language_id === "EN");
    if (EN_message?.text && EN_message.text.length > 0) return EN_message;
    const SE_message = texts.find((text) => text.language_id === "SE");
    if (SE_message?.text && SE_message.text.length > 0) return SE_message;
    const FI_message = texts.find((text) => text.language_id === "FI");
    if (FI_message?.text && FI_message.text.length > 0) return FI_message;
    return null;
  };

  const handleDismissMessage = (messageId: string) => {
    setVisibleMessages(visibleMessages.filter((message) => message.id !== messageId));
  };

  if (visibleMessages.length === 0) return null;

  return (
    <Flex position="relative" zIndex="banner" flexDir="column">
      {visibleMessages.map((currentMessage) => {
        const message = getMessage(currentMessage.texts);
        if (!message) return null;
        return (
          <Alert
            key={currentMessage.id}
            status={currentMessage.severity as SystemMessageSeverity}
            variant="top-accent"
            w="full"
          >
            <AlertIcon />
            <AlertDescription flex="1 1 auto">{message?.text}</AlertDescription>
            {currentMessage.severity !== SystemMessageSeverity.ERROR && (
              <CloseButton
                ml="4"
                alignSelf="flex-start"
                justifySelf="end"
                onClick={() => handleDismissMessage(currentMessage.id)}
              />
            )}
          </Alert>
        );
      })}
    </Flex>
  );
};

export default SystemMessages;
