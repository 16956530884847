import React from "react";
import { DealerRequiredAction } from "./types";
import { ErrorBoundary } from "react-error-boundary";
import DefaultTemplate from "./actionsTemplates/DefaultTemplate";
import { RequiredActionsMapping } from "./actionsTemplates/RequiredActionsMapping";

type Props = {
  action: DealerRequiredAction;
};

type ErrorHandlerProps = {
  error: any;
  action: DealerRequiredAction;
};

const ErrorHandler = ({ error, action }: ErrorHandlerProps) => {
  console.error("RequiredAction / ErrorHandler: ", error, "Action: ", action);
  return <DefaultTemplate action={action} />;
};

const RequiredAction = ({ action }: Props) => {
  const ActionRenderer = RequiredActionsMapping.get(action.action_type);
  const Template = ActionRenderer ? ActionRenderer(action) : null;
  const ActionTemplate = Template || DefaultTemplate;
  return (
    <ErrorBoundary FallbackComponent={({ error }: any) => ErrorHandler({ error, action })}>
      <ActionTemplate action={action} />
    </ErrorBoundary>
  );
};

export default RequiredAction;
