import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IGetContactPayload, IGetContactResponse } from "./types";

export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Contact"],
  endpoints: (builder) => ({
    getContact: builder.query<IGetContactResponse, IGetContactPayload>({
      query: (payload) => {
        const { access_token, contact_id } = payload;
        return {
          url: `/v1/legal_entity/${contact_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Contact"],
    }),
  }),
});

export const { useGetContactQuery } = contactApi;
