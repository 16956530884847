import { Flex, Center, Box, Link } from "@chakra-ui/react";
import { useContext } from "react";
import { useGetSessionQuery } from "../../account/service";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import useTranslate from "../../locale/hooks/useTranslate";
import Icon from "../../shared/Icon";

export default function ContactSupportLink() {
  const accessToken = useContext(AccessTokenContext);
  const translate = useTranslate();

  const { data: userSession } = useGetSessionQuery(
    {
      access_token: accessToken,
    },
    { skip: !accessToken }
  );

  const hfTenantKey = userSession?.hf_tenant_key;

  const supportEmailFI = "laskut@handlarfinans.fi";
  const supportEmailSE = "bilar@handlarfinans.se";
  return (
    <Link
      href={`mailto:${hfTenantKey === "hf_se" ? supportEmailSE : supportEmailFI}`}
      _hover={{ textDecoration: "none" }}
      cursor="pointer"
    >
      <Flex gap="2" align="center">
        <Center borderRadius="full" bgColor="reds.100" p="2" flex="0 0 auto" minW="0">
          <Icon name="contact_support" color="reds.700" size="32px" />
        </Center>

        <Box fontWeight="medium">{translate("required_actions.action_types.contact_support")}</Box>
      </Flex>
    </Link>
  );
}
