export const translations = {
  no_results: "Inga resultat",
  error_warning: "Något gick fel",
  no_items: "Inget att visa",
  download_csv: "Ladda ner lista",
  send_via_email: "Skicka via e-post",
  temporary_unavailable: "tillfälligt otillgänglig",
  support_email: "bilar@handlarfinans.se",
  sweden: "Sverige",
  finland: "Finland",
  dashboard: {
    dashboard: "Startsida",
    summary: "Samanfattning",
    stats: "Statistik",
    pending_deals: "Bearbetas",
    in_inventory: "I lager",
    avg_inventory_days: "Snitt lagerdagar",
    total_deals_made: "Antal genomförda affärer",
    financing: "Finansierat",
    overdue: "Förfallen",
    performing: "Aktiv",
    used: "Nyttjat",
    from: "Tilldelat",
    reserved: "reserved",
  },
  sidebar: {
    files: "Filer",
    dashboard: "Startsida",
    deals: "Affärer",
    support: "Support",
    kyc: "KYC",
    deal_status: {
      pending: "Pågende",
      active: "Aktiva",
      settled: "Avslutade",
    },
    transactions: "Utförda betalningar",
    inventory: "Lager",
    consumer_loans: "Privatlån",
    users_management: "Användare",
    users: "Användare",
    invites: "Inbjudningar",
    settings: "Inställningar",
    company_details: "Bolagsinformation",
  },
  placeholders: {
    vehicle_name: "Fordonets namn",
    inventory_days: "Lagerdagar",
    settled_at: "Avslutad",
    started_at: "Started at",
    activated_at: "Aktiverad",
    labels: "Labels",
    save: "Spara",
    all_content_types: "Alla",
    downpayment_label: "Handpenning (frivillig)",
    downpayment: "Handpenning",
    first_name: "Förnamn",
    last_name: "Efternamn",
    phone: "Telefon",
    email: "E-post",
    phone_no: "Telefonnummer",
    filter: "Filter",
    user: "Användare",
    vin_reg_no: "Chassinummer / Reg.nummer",
    reg_vin: "Reg. / Chassinummer",
    reg_no: "Reg. nummer",
    vin: "Chassinummer",
    make: "Märke",
    model: "Modell",
    make_model: "Märke & Modell",
    model_year: "Model year",
    cancel: "Avbryt",
    upload: "Ladda upp",
    price: "Pris",
    purchase_price_net: "Inköpspris. ex moms",
    purchase_price_vat: "moms",
    dummy_no: "XX,XX",
    mileage: "Mätarställning",
    additional_info: "Extra information",
    your_comment: "Din kommentar",
    not_done: "Ej utfört",
    generate: "Generera",
    files: "Filer",
    fetch: "Hämta",
    fetch_info: "Hämta information",
    confirm: "Bekräfta",
    add_comments: "Lägg till dina kommentarer här...",
    submit: "Skicka in",
    exit: "Lämna",
    created_at: "Skapad",
    created_before: "Skapad före",
    created_after: "Skapad efter",
    updated_at: "Updated at",
    status: "Status",
    seller: "Säljare",
    save_draft: "Spara utkast",
    person: "Person",
    organisation: "Organisation",
    entity_type: "Typ",
    org_no: "Org.nummer",
    ssn: "Personnummer",
    ssn_long: "Personnummer",
    contact_name: "Namn",
    address: "Adress",
    contact: "Kontakt",
    additional_contact: "Lägg till kontakt",
    name: "Namn",
    country_id: "Land",
    select_country: "Välj land",
    individual: "Individ",
    recipient: "Mottagare",
    details: "Detaljer",
    export: "Exportera",
    date: "Datum",
    company_name: "Bolagsnamn",
    post_address: "Adress",
    postal_code: "Postnummer",
    city: "Stad",
    street: "Gatuadress",
    close: "Stäng",
    vehicle: "Fordon",
    iban: "IBAN",
    swift: "SWIFT",
    bic: "BIC",
    account_number: "Kontonummer",
    clearing_number: "Clearing-nummer",
    payment_ref: "Referens",
    swish_number: "Swish number",
    remove: "Ta bort",
    select: "Välj",
    location: "Plats",
    add_new: "Skapa ny",
    edit: "Editera",
    yes: "Ja",
    no: "Nej",
    or: "eller",
    preview_file: "Förhandsgranska",
    costs: "Kostnader",
    none: "-",
    invoice: "Faktura",
    state: "Status",
    any: "Alla",
    countries: "Länder",
    search_country: "Sök land",
    country_or_residence: "Bosättningsland",
    continue: "Fortsätt",
    back: "Tillbaka",
    all: "Alla",
    filter_table: "Filter table",
    export_csv_info:
      "Exports usually take about 30 minutes to compile. We will send you an email when the file is ready.",
    export_successfully_requested: "Export successfully requested",
    export_error: "Export could not be requested",
  },
  deals: {
    overdue: "Förfallna",
    due_days: "{{days}} dagar",
    one_due_day: "1 dag",
    payment_overdue: "Förfallen",
    successfully_created: "Affären skapad",
    create_error_warning: "Något gick fel vid skapande av affären, kontakta oss för hjälp",
    change_deal_success: "Affären uppdaterad",
    change_deal_error: "Något gick fel vid uppdatering, kontakta oss för hjälp",
    add_new_deal: "Ny affär",
    add_new_deal_success: "Affären skapad",
    add_new_deal_error: "Något gick fel vid skapande av affären, kontakta oss för hjälp",
    action_required_message: "Du har en åtgärd att slutföra",
    continue: "Fortsätt",
    go_to_deal: "Gå till affär",
    active_deal: "Aktiv affär",
    vat_deductible: "Momsbil",
    vat_nondeductible: "Ej avdragsgill",
    margin_scheme: "VMB",
    registered_owner: "Registrerad ägare",
    marketplace: "Marknadsplats",
    own_stock: "Eget lager",
    proceed: "Fortsätt",
    request_financing: "Ansök",
    fetch_error: "Vi kunde inte hämta affären, kontakta oss",
    deals: "Affärer",
    no_deal: "Ingen affär hittades",
    ownership: "Ägare",
    payment_required: "Betalning krävs",
    missing_data: "Vi saknar information",
    exit_deal: "Lämna",
    exit_deal_unsaved_changes: "Du har osparade förändringar, om du lämnar nu försvinner dina förändringar",
    data_room: "Filer",
    advance: "Förskott",
    request_offer_success: "Din ansökan om finans har skickats in",
    request_offer_error: "Något gick fel vid din ansökan, kontakta oss",
    financed_amount: "Finansierat belopp",
    inventory_days: "Lagerdagar",
    payment_due_in: "Betalas inom",
    export_transaction_report: "Exportera transaktioner",
    export_transaction_report_success: "Transaktionsrapport skapad",
    export_transaction_report_error: "Något gick fel",
    export_transaction_report_warning: "Transaktionsrapport ej tillgänglig",
    deal_status: {
      initiated: "Utkast",
      offer_requested: "Finans sökt",
      pending: "Pågående",
      offer_accepted: "Erbjudande accepterat",
      prepared: "Förberedd",
      servicing: "Aktiv",
      cancelled: "Avbruten",
      settled: "Slutbetald",
    },
    deal_sections: {
      vehicle: "Fordon",
      dealer: "Handlare",
      purchase: "Inköp",
      offering: "Erbjudande",
      payout: "Utbetalning",
      ownership: "Ägande",
      servicing: "Aktiv",
      contracts: "Avtal",
      payments: "Betalningar",
    },
    export_deals: "Exportera affärer",
    cancel_deal: "Avbryt affär",
    cancel_deal_prompt: "Är du säker på att du vill avbryta affären?",
    cancel_deal_success: "Affären har avbrutits",
    cancel_deal_error: "Misslyckades med att avbryta affären",
  },
  files: {
    last_updated: "Senast uppdaterad",
    file: "Filnamn",
    download_error: "Filnedladdningen misslyckades",
    select_content_type: "Filtyp",
    preview: "Förhandsgranska",
    files: "Filer",
    create_update_at: "Skapad/uppdaterad vid:",
    owner: "Ägare",
    file_name: "Filnamn",
    size: "Storlek",
    no_file: "Inga filer",
    success_message: "Filen raderades",
    error_message: "Filen kan ej raderas",
    download: "Ladda ner",
    delete: "Ta bort",
    upload_failed: "- Uppladdningen misslyckades, försök igen",
    replace_file: "Ersätt fil",
    delete_file: "Ta bort fil",
    delete_warning: "Du är på väg att ta bort {{fileName}}. Är du säker?",
    files_page: "Filer",
    upload_purchase_invoice: "Ladda upp inköpsfaktura",
    drag_drop: "Drag & släpp dina filer här, eller klicka för att välja",
    upload_receipt: "Ladda upp kvittens på betalning i enlighet med instruktionerna ovan",
    upload_file: "Ladda upp fil",
    tag: "Etiketter",
    successfully_uploaded: "Fil(er) har laddats upp",
    error_on_uploaded: "Något gick fel vid uppladdning av {{fileName}} ",
    error_display_file_type: "Denna filtyp kan ej visas",
    labels: {
      general_data: "Allmän data",
      defects: "Skador",
      proforma_invoice: "Proforma faktura",
      supplier_invoice: "Leverantörsfaktura",
      image: "Bild",
      pdf: "PDF",
      purchase_contract: "Köpekontrakt",
      ownership_paper: "Ägarbevis",
      proof_of_vehicle: "Fordonsbevis",
      contract_base: "Avtal",
      all: "Alla",
      report: "Rapport",
      finance: "Finansiering",
      advance_invoice: "Förskottsfaktura",
      purchase_document: "Inköpsdokument",
      contract: "Kontrakt",
      receipt: "Kvitto",
      payout_receipt: "Utbetalningskvitto",
      advance_receipt: "Förskottskvitto",
      ownership: "Ägande",
      servicing: "Servicing",
      vin: "VIN",
      kyc: "KYC",
      customer_invoice: "Customer invoice",
      invoice: "Invoice",
    },
    content_types: {
      "application/pdf": "PDF",
      "text/csv": "CSV",
      "image/jpeg": "JPEG",
      "image/png": "PNG",
    },
    owner_types: {
      internal_user: "Användare",
      external_user: "Användare",
      system: "Torque",
    },
  },
  purchase: {
    indicative_signatory: "Signerande part",
    financier: "Finansiär",
    seller: "Säljare",
    contract_parties: "Avtalsparter",
    labels: {
      debt_amount: "Restskuld",
      debt_vat: "Moms restskuld",
      debt_note: "Ladda upp lösenoffert",
      payment_method: "Betalmetod",
      amount: "Belopp",
    },
    trade_in: "Inbyte",
    trade_in_check: "Detta köp ingår i ett inbyte ",
    sales_price: "Försäljningspris",
    invoice_prompt: "Har du redan en faktura för detta inköp?",
    yes: "Ja",
    no_invoice: "Nej, jag vill skapa ett köpeavtal",
    invoice_info: "Du kan alltid skicka e-post på: ",
    payment_information: "Betalningsinformation",
    invoice: {
      invoice_modal_title: "Fakturadetaljer: {{total}}",
      view_invoice: "Se faktura",
      back_to_invoice: "Tillbaka",
    },
  },
  offering: {
    requested_financing_amount: "Begärt finansbelopp",
    max_financing_amount: "Högsta tillåtna finansbelopp är {{max_amount}}",
    financing_amount_update_failed: "Någonting gick fel när finansbeloppet skulle ändras",
    financing_amount_updated: "Finansbeloppet har uppdaterats",
    invalid_financing_amount: "Otillåtet finansbelopp",
    change_financing_amount: "Ändra finansbelopp",
    estimated_price: "Uppskattat säljpris",
    requested_financing: "Ansökt finansbelopp",
    financing_offer: "Erbjuden finans",
    total_purchase_price: "Totalt inköpspris",
    financing_rate: "Finansgrad",
    financing_amount: "Finansbelopp",
    advance_amount: "Förskottsbelopp",
    monthly_interest: "Månadsränta",
    standard_inv_financing: "Lagerfinans",
    choose_financing: "Välj finansbelopp",
    deal_received: "Vi har fått din förfrågan",
    checking_deal: "Vi kontrollerar nu alla uppgifter och utför värdering, vi återkommer när allt är klart.",
    contact_us: "Kontakta oss",
    accept_offer: "Acceptera erbjudande",
    accept_offer_success: "Erbjudandet accepterat",
    accept_offer_error: "Något gick fel när erbjudande skulle accepteras",
    update_deal: "Uppdatera affär",
    abort: "Avbryt",
    abort_success: "Förfrågan har avbrutits",
    abort_error: "Någonting gick fel när förfrågan avbröts",
    check_deal_status: "Kontrollera status",
  },
  vehicle: {
    description: "Titel",
    successfully_created: "Fordon skapat",
    create_error_warning: "Fordonet kunde inte skapas",
    no_vehicle_added: "Inget fordon har lagts till",
    successfully_edited: "Fordonet uppdaterat",
    edit_error_warning: "Något gick fel vid uppdatering",
    first_reg_date: "Första registreringsdatum",
    current_registration: "NUvarande registrering",
    fuel_type: "Drivmedel",
    fuel_types: {
      petrol: "Bensin",
      diesel: "Diesel",
      electric: "El",
      gas: "Gas",
      hybrid: "Hybrid",
      e85: "E85",
      other: "Annan",
    },
    drive_type: "Drivhjul",
    drive_types: {
      FWD: "FWD",
      RWD: "RWD",
      AWD: "AWD",
    },
    transmission: "Växellåda",
    transmission_types: {
      manual: "Manuell",
      automatic: "Automat",
      other: "Annan",
    },
    power: "Motor",
    VAT_status: "Momsstatus",
    VAT_status_undefined: "Ej definerat",
    deductible: "Momsbil",
    current_owner: "Nuvarande ägare",
    margin_scheme: "VMB",
    estimated_sales_price: "Uppskattat försäljningspris",
    information: "Information",
    fetch_vehicle_info: "Hämta fordonsdata",
    import_error: "Fordonet kunde inte importeras",
    additional_info: "Extra information",
    additional_info_description:
      "Berätta mer om fordonet du köper, t.ex. Skick, utrustning eller annat som utmärker fordonet",
    upload_img_doc: "Ladda upp bilder/testprotokoll",
    search_placeholder: "Search vehicle",
    additional_comment: "Kommentar",
    vehicle_info: "Fordons information",
    no_of_keys_received: "No. of keys received",
    no_of_keys_received_required: "No. of keys received is required",
    additional_tyres_included: "Additional tyres included",
    add_vehicle: {
      search_add_label: "Sök eller skapa fordon",
      add_new: "Skapa fordon",
    },
    vehicle_type: "Fordonstyp",
    vehicle_types: {
      car: "Bil",
      boat: "Båt",
    },
    win: "WIN",
    type_of_boat: "Båttyp",
    boat_types: {
      motor_ship: "Motorbåt",
      sail_ship: "Segelbåt",
      other: "Annan",
    },
    engine_serial_number: "Serienummer",
    engine_model: "Motortyp",
    engine_manufacturing_year: "Tillverkningsår",
    motor_identification: "ID-nummer",
  },
  contracts: {
    add_contract_error: "Add contract error",
    contract_signing_initiated_success: "Avtalet har skapats och skickats ut för signering",
    signatories: "Signerande parter",
    contract_preview: "Förhandsgranskning",
    purchase_contract: "Köpehandlingar",
    generate_contract: "Skapa avtal",
    financing_contract: "Finansavtal",
    other_documents: "Övriga dokument",
    sellers: "Sellers",
    buyers: "Buyers",
    status: {
      init: "Utkast",
      pending: "Väntar",
      closed: "Stängd",
      cancelled: "Avbruten",
      expired: "Utgången",
      rejected: "Nekad",
      error: "Fel",
      delivered: "Levererat",
      viewed: "Visat",
      signed: "Signerat",
    },
    no_signee: "Saknar signerande part",
  },
  payments: {
    pay_advance: "Betala förskott",
    advance_to_info: "Betala förskottet till Handlarfinans",
    pay_now: "Betala direkt",
    advance: "Förskott",
    advance_status: {
      paid: "Betald",
    },
    payouts_from_handlarfinans: "Utbetalingar från Handlarfinans",
    dealer_deposit_state: {
      new: "Ny",
      pending: "Väntar",
      approved: "Godkänd",
      processing: "Behandlas",
      processed: "Betald",
      rejected: "Nekad",
      voided: "Voided",
    },
    payout_task_state: {
      new: "Ny",
      in_process: "Pågående",
      processed: "Betald",
      cancelled: "Avbruten",
      pending: "Väntar",
      authorized: "Bekräftad",
      rejected: "Nekad",
      approved: "Godkänd",
      expired: "Utgången",
    },
    advance_payment_success: "Förskottsbetalning utförd",
    advance_payment_error: "Förskottsbetalningen misslyckades",
    settlement: {
      success: "Affären slutbetald",
      error: "Slutbetalning misslyckades",
    },
    extend: {
      success: "Affären förlängd",
      error: "Förlängning misslyckades",
    },
    payment_link_error: "Vi kunde inte skapa en betallänk, kontakta oss",
  },
  servicing: {
    manual_deposit_success: "Betalkvittens registrerad",
    make_manual_deposit: "Registrera betalkvittens",
    instant_payment: "Direktbetalning",
    financials: "Finans",
    service_deal: "Aktivera affär",
    total_purchase_price: "Totalt inköpspris",
    monthly_interest: "Månadsränta",
    financing_amount: "Finansbelopp",
    amount_to_extend: "Belopp för förlängning",
    amount_to_settle: "Belopp för avslut",
    standard_inv_financing: "Lagerfinansiering",
    make_payment: "Betala",
    extend: "Förläng",
    settle: "Slutbetala",
    pay_instalment: "Utför delbetalning",
    amount_due_in_no_data: "Belopp som förfaller",
    amount_due_in: "Belopp som förfaller om {{days}}",
    amount: "Belopp",
    purpose: "Syfte",
    upload_payment_receipt: "Ladda upp betalkvittens",
    payment_receipt_info: "Du har laddat upp en betalkvittens. Så fort betalningen anlänt kommer affären förlängas",
    payment_details: "Betalningsinformation",
    payment_ref: "Referens",
    iban: "Iban/Kontonummer",
    swift: "SWIFT",
    bankgiro_id: "Bankgiro ID",
    deposits: "Inbetalningar",
    no_deposits: "Inga inbetalningar",
    deposit_purpose_options: {
      settlement: "Slutbetalning",
      extend: "Förlängning",
      advance: "Förskott",
      financing: "Financing",
      dividend: "Dividend",
    },
    payment_unavailable: "Vänligen kontakta support snarast för betalning av denna faktura.",
  },
  section_roles: {
    dealer: "Handlare",
    seller: "Säljare",
    seller_agent: "Marknadsplats",
    seller_financier: "Finansiär",
    vat_deposit_escrow: "Moms-deposition",
    transferor: "Ägarbyte",
  },
  account: {
    my_account: "Mitt konto",
    logout: "Logga ut",
    email: "E-post",
    name: "Namn",
    save: "Spara",
    update_account_success_message: "Kontot uppdaterat",
    update_account_error_message: "Något gick fel vid uppdatering",
  },
  ownership: {
    auth_code: "Kontrollnummer",
    seller_transfers_ownership: "Säljaren har direktanmälan",
    ownership: "Ägarbyte",
    ownership_transfer: "Ägarbyte",
    ownership_papers: "Ägandebevis",
    ownership_manual_transfer: "Ägarbytet sker manuellt (via kontrollnummer)",
    ownership_done: "Utfört",
    ownership_pending: "Väntar",
    ownership_transfer_success_message: "Ägarbytet inskickat",
    ownership_transfer_error_message: "Något gick fel vid ägarbyte",
    ownership_control_number_already_exist: "Transaktionen finns redan",
    ownership_control_number_error: "Ogilitigt kontrollnummer",
  },
  settled: {
    settled_deal: "Slutbetald affär",
    transaction_history: "Betalhistorik",
    purpose: "Typ",
    amount: "Belopp",
  },
  date_time: {
    year: "År",
    years: "år",
    month: "Månad",
    months: "månader",
    weeks: "Veckor",
    week: "Vecka",
    day: "Dag",
    days: "dagar",
    and: "och",
  },
  support: {
    support: "Support",
    unavailable_chat: "Chat är inte tillgängligt",
    load_more: "Ladda fler",
  },
  kyc: {
    draft_save_error: "Något gick fel när utkastet skulle sparas",
    draft_successfully_saved: "Utkastet är sparat",
    form_validation_error: "Please fill in all required fields",
    input_error: "This field is required",
    register_pending_message1: "We are unable to process your request at this moment. Please contact us at ",
    register_pending_message2: " for support",
    application_created_success: "Ansökan har skapats",
    application_created_error: "Någonting gick fel när ansökan skapades",
    update_application_success: "Ansökan uppdaterad",
    update_application_error: "Vi kunde inte uppdatera din ansökan, kontakta oss",
    updated_at: "Senast uppdaterad",
    terms: {
      title: "Välkommen till Handlarfinans!",
      terms_copy:
        "Handlarfinans står under tillsyn av Finansinspektionen och behöver kontinuerligt uppdatera KYC (Know Your Customer) för sina befintliga partners. Detta är en viktig del i arbetet med att bekämpa penningtvätt och finansiering av terrorism. Vi ber dig därför att fylla i svar på frågorna och skicka dem till oss senast den 21 april. I samband med detta kan det krävas att vi behöver ta ett nytt UC för bolaget om det vi tog senast är över 6 månader gammalt samt att vi kommer att behöva ta ett nytt Credit Safe för hela styrelsen och för alla personer som har tecknat ett samarbetsavtal med Handlarfinans om det vi har idag är äldre än 12 månader.",
      accept_terms: "Jag godkänner ovanstående villkor",
    },
    identity: {
      title: "Verifiera din identitet",
      upload_id: "Ladda upp identitetshandling",
      login_with_bankID: "Verifiera identitet med BankID",
      login_without_bankID: "Fortsätt utan BankID",
      id_uploaded_success: "Identitetshandling uppladdad",
      id_uploaded_error: "Något gick fel vid uppladdning",
      replace_id: "Byt ut identitetshandling",
      click_to_replace: "Klicka på bilden för att byta ut",
    },
    application: {
      title: "Slutför ansökan",
      personal_info: "Personlig information",
      finish: "Slutför",
      company: "Bolag",
      company_question: "Vilket bolag vill du ansöka för?",
      other: "Annat",
      confirm_company_info: "Bekräfta bolagsdata",
      confirm_owner: "Bekräfta verklig huvudman",
      beneficial_owner_pep: "Veklig huvudman är PEP (Politiskt utsatt person)",
      yes: "Ja",
      no: "Nej",
      part_of_group: "Ingår företaget i en koncern?",
      org_structure: "Hur ser koncernstrukturen ut?",
      group_active_abroad: "Är något av företagen som ingår i koncernen registrerat i ett annat land än Sverige?",
      org_countries_abroad: "Vilka länder?",
      owners_years_experience_in_car_sale: "Företrädarnas erfarenhet från bilförsäljning (år)?",
      purpose: "Ditt syfte med Handlarfinans",
      inventory_financing: "Lagerfinansiering",
      expansion: "Expansion",
      debt_financing: "Skuldfinansiering",
      marketing_activities: "Marknadsföring",
      purpose_other: "Skriv annan anledning",
      vehicle_types_question: "Vilken typ av fordon säljer du?",
      passenger_cars: "Passagerarbilar",
      sport_cars: "Sportbilar",
      transport_vehicles: "Transportfordon",
      campers: "Husvagn & Husbilar",
      snowmobiles_atv: "Snöscooters och terrängfordon",
      trailers: "Släp",
      purchase_price_range:
        "Vilket prisintervall stämmer mest överens med de inköpspriser ert företag vanligtvis köper fordon för?",
      up_to_price: "Upp till 300 000 SEK",
      between_price: "300 000 - 500 000 SEK",
      price_and_above: "500 000 SEK och över",
      garage_info: "Din försäljningsyta",
      enter_webpage: "På vilken webbplats marknadsför du fordon till salu",
      online_shop_url: "Länk till webbplats",
      enter_store_address: "På vilken adress lagerför och säljer du fordon",
      same_as_company_address: "Samma som företagets adress",
      enter_garage_size: "Ange storlek på fordonshall och gård",
      garage_size: "Fordonshall",
      cars_in_garage: "Antal fordon i hallen",
      yard_size: "Gårdsstorlek",
      cars_in_yard: "Antal fordon på gården",
      about_your_business: "Om ditt företag",
      owner_industry_experience: "Ägarnas erfarenhet från branschen (Antal år)",
      employees_no: "Antal anställda (Hel- och deltid)",
      other_income_sources: "Övriga inkomstkällor",
      cash_manage: "Hanterar företaget kontanter?",
      cash_manage_options: {
        frequently: "Ja, relativt frekvent",
        as_an_exception: "Ja, i undantagsfall och enbart om kund kan påvisa medlens ursprung",
        rarely: "Ja, ej frekvent och enbart vid betalning av kontantinsatser",
        no: "Nej",
      },
      vehicle_sourcing_channels: "Vart köper företaget majoriteten av fordonen ifrån?",
      vehicle_sourcing_channel: {
        auctions: "Auktioner",
        car_dealers: "Andra bilhandlare",
        other_companies: "Andra företag",
        private_individuals: "Privatpersoner",
        import: "Importerade",
      },
      dealership_photos: "Foton på försäljningslokal",
      dealership_photos_info: "Lägg till minst ett foto på bilhall samt ett på gård",
      upload_images: "Ladda upp foton",
      budget: "Budget",
      estimated_purchase_costs: "Uppskattade inköpskostnader för fordon",
      gross_profit: "Bruttovinst",
      expected_turnover: "Förväntad omsättning",
      facility_expenses: "Lokalkostnader",
      salaries_and_employee_costs: "Personalkostnader",
      other_expenses: "Övriga kostnader",
      expected_result: "Budgeterat resultat",
      board_members: "Styrelsemedlemmar",
      add_another: "Lägg till",
      role: "Roll",
      holder: "Innehavare",
      commissioner: "Ledamot",
      substitute: "Suppleant",
      ceo: "Verkställande direktör",
      deputy_ceo: "Vice verkställande direktör",
      member: "Bolagsman",
      chairman: "Ordförande",
      alternate_deputy_substitute_ceo: "Ställföreträdande VD",
      executive_member: "Verkställande ledamot",
      vice_chairman: "Vice ordförande",
      percentage_of_ownership: "Ägarandel",
      ownership_range: {
        0: "0%",
        "0_25": "0-25%",
        "25_50": "Mer än 25% men inte mer än 50%",
        "50_75": "Mer än 50% men inte mer än 75%",
        "75_100": "Mer än 75% Men inte mer än 100%",
        full: "100%",
      },
      is_beneficial_owner: "Är verklig huvudman?",
      is_pep: "Är PEP (Politiskt utsatt person)?",
      has_additional_citizenship: "Har medborgarskap i andra länder?",
      additional_citizenship: "Länder med medborgarskap",
      additional_citizenship_placeholder: "Finland, Norge, ...",
      control_type: "Typ av kontroll",
      control_type_options: {
        art10:
          "Personen har kontroll genom aktier, andelar, medlemskap, avtal eller bestämmelse i exempelvis bolagsordning eller stadgar.",
        art14: "Personen är styrelseledamot eller motsvarande befattningshavare.",
        art20:
          "Personen har rätt att utse eller avsätta mer än hälften av styrelseledamöterna eller motsvarande befattningshavare.",
        art30: "Personen har kontroll tillsammans med närstående.",
        art40: "Personen har kontroll genom andra företag eller föreningar.",
        art74: "Personen utövar kontroll på annat sätt.",
        no_control: "The person has no control.",
      },
    },
    application_submitted: {
      title: "Bra jobbat!",
      submission_info:
        "Vi har tagit emot din ansökan. En av våra anställda kommer kontrollera all information du skickat in. Vi återkommer med besked eller eventuella frågor",
    },
    application_rejected: {
      title: "Ansökan nekad",
      rejection_info: "Din ansökan har nekats. Vänligen kontakta oss på support@handlarfinans.se",
    },
  },
  required_actions: {
    title: "Du har en åtgärd att utföra",
    title_plural: "Du har {{count}} åtgärder att utföra",
    description: "Innan du kan ansöka om en ny affär måste du slutföra följande",
    card_title: "Åtgärder att utföra",
    no_actions: "Du har inga åtgärder att utföra",
    action_types: {
      ownership_transfer_outstanding: "Ägarbytet är ej genomfört på ",
      payment_outstanding: "Du har förfallna affärer att betala",
      credit_limit_exceeded: "Du har ingen tillgänglig limit",
      contact_support: "Kontakta vår support",
      kyc_required: "Du behöver fylla i formulär för kundkännedom",
    },
  },
  bank_id_auth: {
    try_again: "Try again",
    authentication_failed: "BankID authentication failed.",
    title: "BankID identifiering",
    error: "Något gick fel vid autentisering med BankID",
    success: "BankID godkänt",
    open_app: "Öppna BankID",
    hint_messages: {
      RFA1: "Starta BankID-appen.",
      RFA9: "Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.",
      RFA13: "Försöker starta BankID-appen.",
      RFA15:
        "Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.",
      RFA21: "Identifiering eller underskrift pågår.",
      RFA23: "Fotografera och läs av din ID-handling med BankID-appen.",
    },
  },
  transactions: {
    transactions_title: "Utförda betalningar",
    select_period: "Välj en tidsperiod",
    custom: "Fritt val",
    last_30_days: "Senaste 30 dagarna",
    current_quarter: "Nuvarande kvartal",
    from_date: "Från datum",
    to_date: "Till datum",
    generate_transaction_pdf: "Skapa betalningsfil",
    generated_files: "Skapade filer",
    action: "åtgärd",
    file_generated_successfully: "Filen skapades",
    file_generation_error: "Något gick fel",
  },
  inventory: {
    inventory: "Lager",
    any_location_status: "Plats",
    new_vehicle_purchase: "Nytt inköp",
    edit_vehicle_purchase: "Editera inköp",
    purchase: "Inköp",
    purchase_price: "Inköpspris",
    purchase_price_inc_vat: "Inköpspris ink. moms",
    purchase_date: "Inköpsdatum",
    purchase_date_from_doc: "Inköpsdatum (enligt kvitto/faktura)",
    purchased_by: "Inköpt av",
    purchase_note: "Purchase note",
    purchase_doc_label: "Inköpsdokument",
    purchase_channel: "Inköpskanal",
    purchase_information: "Inköpsinformation",
    upload_purchase_doc: "Ladda upp inköpsdokument",
    purchase_doc_sent: "Inköpsdokument skickat",
    register_purchase: "Registrera inköp",
    expected_sale_price: "Förväntat säljpris",
    sale_doc: "Försäljningsdokument",
    sale_channel: "Försäljningskanal",
    sold_vehicle_details: "Sålt fordon",
    sale_information: "Försäljningsinformation",
    sale_date: "Försäljningsdatum",
    total_selling_price: "Totalt säljpris",
    sold_with_warranty: "Såld med garanti",
    sold_with_financing: "Såld med finans",
    upload_sale_doc: "Ladda upp försäljningsdokument",
    marketplace: "Marketplace",
    inventory_vehicle_details: "Lagerfordon",
    interest_rate: "Ränta",
    warranty: "Garanti",
    warranty_provider: "Garantigivare",
    financed: "Finansierad",
    financier: "Finansiär",
    customer_type: "Kundtyp",
    customer_country: "Land",
    first_reg_date: "Första reg.datum",
    vat_status: "Moms status",
    advance_payment: "Erlagd handpenning",
    advance_amount: "Handpenningsbelopp",
    upload_advance_invoice: "Ladda upp handepenningsunderlag",
    advance_payment_spec_sent: "Handpenningsunderlag skickat",
    total_logistics: "Totala logistikkostnader",
    total_logistics_net: "Total logistik netto",
    total_logistics_vat: "Total logistik moms",
    last_logistic_date: "Senaste datum",
    current_location: "Nuvarande plats",
    location_status_label: "Plats status",
    location_label: {
      dealership: "Bilhall",
      in_transit: "Under transport",
      repair_shop: "Verkstad",
      other: "Annat",
    },
    ownership_status: {
      in_stock: "I lager",
      sold: "Såld",
      stolen: "Stulen",
      scrapped: "Skrotad",
      unknown: "Okänd",
    },
    purchase_doc: {
      invoice: "Faktura",
      purchase_agreement: "Köpavtal",
    },
    purchase_channel_options: {
      lead_from_private_person: "Lead from private person",
      lead_from_company: "Lead from company",
      blocket_ad_from_car_dealer: "Blocket ad from car dealer",
      blocket_ad_from_other: "Blocket ad from other",
      marketplace: "Marketplace",
      reoccurring_customer: "Reoccurring customer",
    },
    marketplace_options: {
      autorola: "Autorola",
      bca: "BCA",
      kvd: "KVD",
      klaravik: "Klaravik",
    },
    sale_doc_options: {
      car_dealer: "Bilhandlare",
      private_person: "Privatperson",
      blocket: "Blocket",
      kvd: "KVD",
      bca: "BCA",
      auto1: "Auto1",
      other: "Annat",
      purchase_agreement: "Köpavtal",
    },
    sale_channel_options: {
      showroom: "Bilhall",
      blocket: "Blocket",
      other: "Annat",
    },
    ownership_status_filters: {
      all: "Alla fordon",
      in_stock: "I lager",
      sold: "Sålda fordon",
    },
    customer_type_options: {
      private: "Privatkund",
      company: "Företag",
    },
    customer_country_options: {
      SE: "Sverige",
      FI: "Finland",
    },
    messages: {
      new_transaction_added: "Ny transaktion registrerad",
      new_transaction_error: "Något gick fel",
      vehicle_updated: "Fordonslager uppdaterat",
      vehicle_update_failed: "Något gick fel",
      location_updated: "Plats uppdaterad",
      location_update_failed: "SNågot gick fel",
      inventory_vehicle_added: "Fordon tillagt i lager",
    },
    warranty_providers: {
      svensk_bilhandels: "Svensk Bilhandelsförsäkring",
      solid: "Solid",
      fragus: "Fragus",
    },
  },
  register_action: {
    mark_as_sold: "Markera som såld",
    reg_new_action: "Ny händelse",
    reg_costs: "Registrera kostnader",
    update_location: "Uppdatera plats",
    sell_vehicle: "Sälj fordon",
    cost_type: "Kostnadstyp",
    cost_amount: "Total kostnad",
    cost_amount_net: "Kostnad netto",
    vat_amount: "moms",
    comment: "CKommentar",
    upload_cost_invoice: "Ladda upp faktura/kvitto",
    vehicle_current_location: "Nuvarande plats",
    set_status: "Ändra status",
    reg_vehicle_sell: "Registrera försälning",
    cost_types: {
      expenses: "Kostnader",
      expense: "Kostnad",
      reimbursements: "Återbetalningar",
      reimbursement: "Återbetalning",
    },
  },
  events: {
    event: "Händelse",
    events: "Händelser",
    vehicle: "Fordon",
    vehicle_camel_case: "Fordon",
    new: "Ett nytt",
    a: "Ett",
    by: "av",
    was_created: "Skapades",
    was_created_by: "skapades av",
    was_deleted: "raderades av",
    was_updated: "uppdaterades av",
    created_by: "skapad av",
    deleted_by: "raderad av",
    updated_by: "uppdaterad av",
    total_count: "Event log ({{total_count}})",
    load_more: "hämta mer",
  },
  event_logs: {
    no_logs: "Inga registerade event",
    no_tags: "Inga taggar tillgängliga",
  },
  upcoming_events: {
    upcoming_events: "Kommande händelser",
    upcoming_payment: {
      message: "Betalning av {{amount}} förfaller {{date}} för {{vehicle_title}}",
    },
  },
  bank_account_config: {
    bank_representation_type: "Kontotyp",
    payout_option: "Payout option",
  },
  payment_plan: {
    show_payment_plan: "Visa betalningsplan",
    payment_plan_title: "Betalningsplan",
    no_payment_plan: "Ingen betalningsplan",
    payment_date: "Betalningsdatum",
    amount: "Belopp",
    interest_amount: "Ränta",
    principal_amount: "Kapitalbelopp",
    remaining_balance: "Kvarstående belopp",
  },
  invoice_details: {
    invoice_title: "Faktura #{{invoiceNo}}",
    invoice_no: "Fakturanummer",
    purpose: "Avser",
    total: "Totalt",
    status: "Status",
    net: "Netto",
    vat: "Moms",
    due: "Due",
    balance: "Balance",
    description: "Description",
    invoice_types: {
      advance: "Förskott",
      financing: "Finans",
      credit_financing: "Kreditfaktura",
      credit_balancing: "Kreditfaktura",
      interest: "Räntefaktura",
    },
    invoice_status: {
      paid: "Betald",
      paid_invoice: "Betald faktura",
      unpaid: "Obetald",
      overdue: "Förfallen",
      unbooked: "Ej bokförd",
      booked: "Bokförd",
    },
  },
  tags: {
    internal_user: "Administratör",
    external_user: "Kund",
    vehicle: "Fordon",
    file: "Fil",
    legal_entity: "Kontakt",
    dealer_credit: "Kredit",
    dealer_account: "Handlarkonto",
    deal: "Affär",
    vehicle_site_audit: "Fordonsplatsbesiktning",
    kyc_application: "KYC-ansökan",
    outbound_email: "Utgående email",
    dealer_location: "Garage",
    monitoring_schedule: "Monitoreringsschema",
    vehicle_valuation: "Fordonsvärdering",
    signing_process: "Signeringsprocess",
    payout_task: "Utbetalningsprocess",
    invoice: "Faktura",
    state_assertion: "Verifikation",
    see_credit: "Se krediter",
  },
  deal_validation: {
    invalid_deal: "Handlarfinans begränsningar",
    back_to_dashboard: "Tillbaka till startsida",
    invalid_deal_details: "Detta fordon faller under Handlarfinans begränsningar, vi kan tyvärr inte erbjuda finans.",
  },
  suggested_input_value: {
    popover_trigger: "Suggested value popover trigger",
    vat_rate: "Momssats: {{rate}}%",
    use: "Använd",
  },
  onboarding: {
    account_info: "Kontoinformation",
    start_info: "Låt oss börja med information om dig",
    company_info: "Bolagsinformation",
    company_info_description: "Vilket bolag ska knytas till detta konto?",
    no_bank_id: "Jag har inget BankID",
    continue_with_bank_id: "Fortsätt med BankID",
    fi_coming_soon_title: "Sign-up kommer snart!",
    fi_coming_soon_details: "Om du vill bli kund, vänligen kontakta oss via email laskut@handlarfinans.fi",
    legal_entity_created: "Juridisk person skapad",
    legal_entity_error: "Något gick fel i samband med skapandet av juridisk person",
    se_temp_unavailable_title: "Vi kan inte hantera er förfrågan för tillfället.",
    se_temp_unavailable_details: "Vänligen kontakta oss via email bilar@handlarfinans.se för support.",
    no_companies_title: "Vi hittade inga bolag under er kontroll",
    no_companies_details: "Vänligen kontakta oss via email bilar@handlarfinans.se för assistans.",
    continue: "Fortsätt",
    submit_identity_data_error: "Någonting gick fel när informationen sparades",
    verification_pending_title: "Vi har mottagit din ansökan",
    verification_pending_details: "Vi granskar ansökan och informerar via email så snart den är färdigbehandlad.",
    verification_rejected_title: "Er ansökan har avslagits",
    verification_rejected_details: "Vänligen kontakta oss via email bilar@handlarfinans.se för support",
    fill_all_required_fields: "Vänligen fyll i samtliga angivna fält",
  },
  loans: {
    cancel_success: "Låneansökan avbruten",
    cancel_error_warning: "Någonting gick fel",
    cancel_application_warning: "Är du säker på att du vill avbryta låneansökan?",
    cancel_application: "Avbryt låneansökan",
    loan_application: "Låneansökan",
    product: "Kreditprodukt",
    create_success: "Låneansökan skapad. Ett SMS har skickats till låntagaren med vidare instruktioner",
    create_error_warning: "Någonting gick fel när låneansökan skapades",
    fill_all_fields: "Samtliga fält måste fyllas i",
    email_or_phone_required: "Vänligen ange ett giltigt telefonnummer",
    initiate_application: "Skapa ansökan",
    credit_product: "Kreditprodukt",
    loans_title: "Låneansökningar",
    applicant_email: "Låntagarens mailaddress",
    applicant_phone_no: "Låntagarens telefonnummer",
    credit_amount: "Belopp",
    new_loan_application: "Ny låneansökan",
    new_applicant: "Ny låntagare",
    applicant_info: "Information om låntagare",
    loan_application_details: "Information om lånet",
    loan_details: "Information om lånet",
    credit_amount_warning: "Totala lånebeloppet måste vara jämnt delbart med 250.",
    credit_amount_min_warning: "Minsta möjliga lånebelopp är {{min_amount}}",
    credit_amount_max_warning: "Maximalt lånebelopp är {{max_amount}}",
    repayment_period_min_warning: "Minsta möjliga återbetalningsperiod är {{min_period}}",
    repayment_period_max_warning: "Längsta möjliga återbetalningsperiod är {{max_period}}",
    loan_status: {
      new: "Ny",
      pending: "Behandlas",
      approved: "Godkänd",
      denied: "Avslag",
      paid_out: "Utbetald",
      error: "Fel",
      signed: "Signerad",
    },
    filters: {
      all: "Alla",
      new: "Nya",
      pending: "Under behandling",
      manual_check: "Granskas",
      approved: "Godkända",
      denied: "Avslagna",
      paid_out: "Utbetalda",
      error: "Felaktiga",
      signed: "Signerade",
    },
    product_key: {
      blanco: "Blanco",
    },
    loan_calculator_example: "Lånekalkyl",
    years: "år",
    repayment_period: "Återbetalningsperiod",
    monthly_cost: "Månadskostnad",
    calculation_example:
      "Beräkningen är ungefärlig. Kunden får exakt månadskostnad samt uppgifter om avgifter och effektivränta som första steg i ansökan.",
    no_applications: "Inga ansökningar",
    dealer_details: "Handlaruppgifter",
    financed_by_hf: "Finansierad av Handlarfinans",
    other: "Annat",
    amount_to_settle: "Utestående belopp",
    earnings_using_blanco_loan: "Earnings using Blanco loan",
    get_offer: "Get offer",
    settle_with_consumer_loan: "Earn up to {{bonus}} by using Consumer loans to settle this deal.",
    apply_now: "Ansök nu",
    offer_unavailable: "Offer unavailable",
    offer_fetched: "Loan offer was successfully fetched",
    error_fetching_offer: "Something went wrong while fetching the loan offer",
    offer_type: "Offer type",
    discount_percentage: "Discount percentage",
    discount_amount: "Discount amount",
    amount: "Amount",
    helper_title: "Så här enkelt skapar du en låneansökan",
    helper_steps: {
      step_one: "Fyll i belopp och låntagarens kontaktuppgifter",
      step_two: `Klicka på "Skapa låneansökan"`,
      step_three: "En länk med referens till ert bolagsnamn skickas ut till låntagaren på både mail och sms",
      step_four: "Låntagaren slutför låneansökan i via länken",
      step_five: "Beslut fattas automatiskt och kunden får besked direkt om sin ansökan",
      step_six: "Pengarna betalas ut till dig som handlare inom 2 bankdagar",
    },
  },
  system_messages: {
    info: "Info",
    warning: "Varning",
    error: "Fel",
  },
  users_management: {
    user_remove_success: "Användaren borttagen",
    user_remove_error: "Någonting gick fel",
    remove_external_user: "Ta bort användare",
    remove_user_warning: "Är du säker på att du vill ta bort denna användare?",
    page_title: "Användare",
    users: "Användare",
    invites: "Inbjudningar",
    invite_new_user: "Bjud in ny användare",
    invite_user: "Skicka inbjudan",
    invite_user_success: "Inbjudan har skickats",
    invite_user_error: "Någonting gick fel i skapandet av inbjudan",
    resend_invite: "Skicka inbjudan igen",
    resend_invite_success: "Inbjudan har skickats på nytt",
    resend_invite_error: "Någonting gick fel när inbjudan skickades",
    cancel_invite: "Avbryt inbjudan",
    cancel_invite_success: "Inbjudningen har dragits tillbaka",
    cancel_invite_error: "Någonting gick fel när inbjudan togs bort",
    save_user: "Spara användare",
    state: "Status",
    external_user_state_options: {
      new: "Ny",
      active: "Aktiv",
      deleted: "Borttagen",
    },
    external_user_state_filters: {
      new: "Nya",
      active: "Aktiva",
    },
    filter_users: "Filtrera användare",
    user_role: "Användarroller",
    select_role: "Välj roll",
    external_user_roles_options: {
      owner: "Ägare",
      employee: "Anställd",
      manager: "Administratör",
      accountant: "Ekonomi",
      prospect: "Prospect",
    },
    fetch_roles_error: "Någonting gick fel när rollerna hämtades",
    edit_user: "Uppdatera användare",
    set_roles_success: "Användarrollerna har uppdaterats",
    set_roles_error: "Någonting gick fel när rollerna uppdaterades",
    revoke_role_success: "Rollen har tagits bort",
    revoke_role_error: "Någonting gick fel när rollen togs bort",
    grant_role_success: "Rollen har lagts till",
    grant_role_error: "Någonting gick fel när rollen lades till",
  },
  dealer_account: {
    edit_info: "Uppdatera bolagsinformation",
    account_update_success: "Account information was successfully updated",
    account_update_error: "Something went wrong while updating account information",
  },
  notes: {
    note_updated: "Anteckningen har uppdaterats",
    notes: "Anteckningar",
    deal_notes: "Anteckningar gällande Affären",
    add_note: "Ny anteckning",
    successfully_deleted: "Anteckningen har tagits bort",
    delete_error: "Anteckningen kunde inte tas bort",
    delete_note: "Radera anteckning",
    delete_warning: "Radera anteckningen. Är du säker?",
    cancel: "Avbryt",
    delete: "Radera",
    edit: "Uppdatera",
    edit_note: "Uppdatera anteckning",
    load_more: "Ladda fler",
    no_notes: "Hittade inga anteckningar.",
  },
};
