import { useAuth0 } from "@auth0/auth0-react";
import { RUN_LOCALLY, LOCAL_AUTH_URL } from "../../config";
const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  if (RUN_LOCALLY) {
    window.location.href = LOCAL_AUTH_URL;
    return null;
  }
  loginWithRedirect();

  return null;
};

export default LoginPage;
