const menu = {
  parts: ["button", "list", "item", "groupTitle", "command", "divider"],
  baseStyle: {
    list: {
      borderRadius: "0",
      padding: "0",
      border: "1px",
      borderColor: "purple.500",
      boxShadow: "base",
      color: "grayDark.500",
      minWidth: "auto",
      zIndex: "3"
    },
    item: {
      padding: "0 12px",
      height: "40px",
      _hover: {
        bg: "grayLight.500",
      },
    },
  },
  sizes: {
    md: {
      list: {
      },
    },
  },
  variants: {
    filter: {
      button: {
        display: "flex",
        alignItems: "center",
        padding: "8px 12px",
        borderRadius: "0",
        border: "1px",
        borderColor: "gray.300",
        color: "gray.600",
        background: "#fff",         
        _hover: {
          borderColor: "purple.500",
        },
        _active: {
          borderColor: "purple.500",
        },
        _disabled: {
          color: "gray.500",
          background: "rgba(197, 197, 197, 0.1)",
          _hover: {
            color: "gray.500",
            borderColor: "gray.300",
          }
        },
      },
      list: {
        shadow: 'dropdown',
        maxHeight: "512px",
        overflow: "auto",
      },
      item: {
        _hover: {
          background: "transparent",
        }
      }
    }
  },
};

export default menu;