import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetDealsPayload,
  IGetDealsResponse,
  IGetDealPayload,
  IGetDealResponse,
  IPostDealPayload,
  IPostDealResponse,
  IPatchDealPayload,
  IPatchDealResponse,
  IGetContractsPayload,
  IGetContractsResponse,
  IRequestOfferPayload,
  IAcceptOfferPayload,
  IGetInvoicesPayload,
  IGetInvoicesResponse,
  IGetPayoutTasksResponse,
  IGetPayoutTasksPayload,
  IGetDealerDepositPayload,
  IDealerDepositResponse,
  IDealerCreditResponse,
  IGetDealerCreditPayload,
  IGetDealFilesPayload,
  IGetDealFilesResponse,
  IGetOwnershipHandlerPayload,
  IGetOwnershipHandlerResponse,
  IPostOwnershipTradeInPayload,
  IPostOwnershipTradeInResponse,
  IGetOwnershipStatusResponse,
  IGetOwnershipStatusPayload,
  IFetchContractTemplatesPayload,
  IFetchContractTemplatesResponse,
  ICreateContractPreviewPayload,
  ICreateContractPayload,
  IGetInvoicePayload,
  IGetInvoiceResponse,
  IFetchContractSignersPayload,
  IFetchContractSignersResponse,
  IPostValidateControlNumberPayload,
  IPostValidateControlNumberResponse,
  IFetchPaymentPlanPayload,
  IFetchPaymentPlanResponse,
  ICheckDealValidityPayload,
  ICheckDealValidityResponse,
  IGetVatRatesResponse,
  IGetVatRatesPayload,
  ICreateDealerDepositPayload,
  ICreateDealerDepositResponse,
  IInvalidateDealStructurePayload,
  IInvalidateDealStructureResponse,
  IRequestNewOfferPayload,
  IRequestNewOfferResponse,
  IExportDealsPayload,
  IExportDealsResponse,
  ICancelDealResponse,
  ICancelDealPayload,
} from "./types";

export const dealsApi = createApi({
  reducerPath: "dealsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Deal", "Invoice", "PayoutTask", "DealPaymentPlan"],
  endpoints: builder => ({
    getDeals: builder.query<IGetDealsResponse, IGetDealsPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/deal",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getDeal: builder.query<IGetDealResponse, IGetDealPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createDeal: builder.mutation<IPostDealResponse, IPostDealPayload>({
      query: ({ access_token, ...body }) => {
        return {
          url: "/v1/deal",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    patchDeal: builder.mutation<IPatchDealResponse, IPatchDealPayload>({
      query: ({ access_token, deal_id, deal, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}`,
          method: "PATCH",
          body: { ...deal, revision_id },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getContracts: builder.query<IGetContractsResponse, IGetContractsPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: `/v1/contract`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    requestOffer: builder.mutation<{ revision_id: string }, IRequestOfferPayload>({
      query: ({ access_token, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/request_offer`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    acceptOffer: builder.mutation<{}, IAcceptOfferPayload>({
      query: ({ access_token, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/accept_offer`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getInvoices: builder.query<IGetInvoicesResponse, IGetInvoicesPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: `/v1/invoices`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Invoice"],
    }),
    getInvoice: builder.query<IGetInvoiceResponse, IGetInvoicePayload>({
      query: ({ access_token, invoiceId }) => {
        return {
          url: `/v1/invoices/${invoiceId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Invoice"],
    }),
    getPayoutTasks: builder.query<IGetPayoutTasksResponse, IGetPayoutTasksPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/payout_task`,
          method: "GET",
          params: { tag: `deal:${deal_id}` },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["PayoutTask"],
    }),
    getDealerDeposit: builder.query<IDealerDepositResponse, IGetDealerDepositPayload>({
      query: ({ access_token, deal_id, dealer_id, ...queryParams }) => {
        return {
          url: `/v1/dealer_deposit`,
          method: "GET",
          params: { dealer_id, tag: `deal:${deal_id}`, ...queryParams },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createDealerDeposit: builder.mutation<ICreateDealerDepositResponse, ICreateDealerDepositPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/dealer_deposit`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getCredit: builder.query<IDealerCreditResponse, IGetDealerCreditPayload>({
      query: ({ access_token, credit_id }) => {
        return {
          url: `/v1/credit/${credit_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    getDealFiles: builder.query<IGetDealFilesResponse, IGetDealFilesPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/file`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getOwnershipHandler: builder.query<IGetOwnershipHandlerResponse, IGetOwnershipHandlerPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/ownership_handler`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    validateControlNumber: builder.mutation<IPostValidateControlNumberResponse, IPostValidateControlNumberPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: "v1/integration/fragus/validate_control_number",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    ownershipTradeIn: builder.mutation<IPostOwnershipTradeInResponse, IPostOwnershipTradeInPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `v1/integration/fragus/ownership_trade_in`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getOwnershipStatus: builder.query<IGetOwnershipStatusResponse, IGetOwnershipStatusPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/integration/fragus/ownership_trade_in_status`,
          method: "GET",
          params: { deal_id },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getContractTemplates: builder.query<IFetchContractTemplatesResponse, IFetchContractTemplatesPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/contract_template`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createContractPreview: builder.mutation<{ html: string }, ICreateContractPreviewPayload>({
      query: ({ access_token, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/preview_contract`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    createContract: builder.mutation<{ process_id: string }, ICreateContractPayload>({
      query: ({ access_token, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/contract`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    fetchContractSigners: builder.query<IFetchContractSignersResponse, IFetchContractSignersPayload>({
      query: ({ access_token, dealId, ...params }) => {
        return {
          url: `/v1/deal/${dealId}/contract_signers`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),

    fetchPaymentPlan: builder.query<IFetchPaymentPlanResponse, IFetchPaymentPlanPayload>({
      query: ({ access_token, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}/offer_payment_plan`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["DealPaymentPlan"],
    }),
    checkDealValidity: builder.query<ICheckDealValidityResponse, ICheckDealValidityPayload>({
      query: ({ access_token, dealId }) => {
        return {
          url: `/v1/deal/${dealId}/validate`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getVatRates: builder.query<IGetVatRatesResponse, IGetVatRatesPayload>({
      query: ({ access_token, hf_tenant_key }) => {
        return {
          url: `/v1/vat_table`,
          method: "GET",
          params: { hf_tenant_key },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    invalidateDealStructure: builder.mutation<IInvalidateDealStructureResponse, IInvalidateDealStructurePayload>({
      query: ({ access_token, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}/invalidate_deal_structure`,
          method: "PUT",
          body: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    requestNewFinancingOffer: builder.mutation<IRequestNewOfferResponse, IRequestNewOfferPayload>({
      query: ({ access_token, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/offer_reduced_financing`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal", "DealPaymentPlan"],
    }),
    exportDeals: builder.mutation<IExportDealsResponse, IExportDealsPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: `/v1/deal_export`,
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    cancelDeal: builder.mutation<ICancelDealResponse, ICancelDealPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
  }),
});

export const {
  useGetDealsQuery,
  useGetDealQuery,
  useCreateDealMutation,
  usePatchDealMutation,
  useGetContractsQuery,
  useRequestOfferMutation,
  useAcceptOfferMutation,
  useGetInvoicesQuery,
  useGetPayoutTasksQuery,
  useGetDealerDepositQuery,
  useGetCreditQuery,
  useGetDealFilesQuery,
  useGetOwnershipHandlerQuery,
  useOwnershipTradeInMutation,
  useGetOwnershipStatusQuery,
  useGetContractTemplatesQuery,
  useCreateContractPreviewMutation,
  useCreateContractMutation,
  useGetInvoiceQuery,
  useFetchContractSignersQuery,
  useValidateControlNumberMutation,
  useFetchPaymentPlanQuery,
  useCheckDealValidityQuery,
  useGetVatRatesQuery,
  useCreateDealerDepositMutation,
  useInvalidateDealStructureMutation,
  useRequestNewFinancingOfferMutation,
  useExportDealsMutation,
  useCancelDealMutation,
} = dealsApi;
