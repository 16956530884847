import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IFetchFinanceStatisticsPayload,
  IFetchFinanceStatisticsResponse,
  IFetchDealsStatisticsPayload,
  IFetchDealsStatisticsResponse,
} from "./types";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    fetchFinanceStatistics: builder.query<IFetchFinanceStatisticsResponse, IFetchFinanceStatisticsPayload>({
      query: (payload) => {
        const { access_token } = payload;
        return {
          url: "v1/dashboard/finance",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Dashboard"],
    }),
    fetchDealsStatistics: builder.query<IFetchDealsStatisticsResponse, IFetchDealsStatisticsPayload>({
      query: ({ access_token }) => {
        return {
          url: "/v1/dashboard/deals",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Dashboard"],
    }),
  }),
});

export const { useFetchFinanceStatisticsQuery, useFetchDealsStatisticsQuery } = dashboardApi;
