const modal = {
  parts: ["header", "overlay", "dialogContainer", "dialog", "closeButton", "body", "footer"],
  baseStyle: {
    dialogContainer: {
      height: "100%",
    },
    dialog: {
      borderRadius: {base: 0, lg: "12px"},
      height: {base: "100%", lg: "auto"},
      my: {base: "0", lg: 16},
      maxH: {base: "unset", lg: "calc(100% - 7.5rem)"},
    },
    overlay: {
      bg: "overlay.500",
    },
    closeButton: {
      top: 0,
      right: 0,
      borderRadius: {base: 0, lg: "0 12px"},
      color: "grayMid.500",
      borderBottom: "1px",
      borderLeft: "1px",
      borderColor: "grayLight.500",
    },
    header: {
      p: 4,
      fontSize: "xl",
      lineHeight: "xl",
      fontWeight: "semibold",
      borderBottom: "1px",
      borderColor: "grayLight.500",
    },
    footer: {
      p: 4,
      borderTop: "1px",
      borderColor: "grayLight.500",
    },
    body: {
      p: 4,
    },
  },
};

export default modal;
