import { IAuthParams } from "../auth/types";

export enum FeatureFlagEnum {
  CHAT_ENABLED = "chat_enabled",
  BLANCO_LOANS_ENABLED = "blanco_loans_enabled",
  VEHICLE_INVENTORY = "vehicle_inventory",
  USER_MANAGEMENT = "user_management",
  CREDIT_BONUS_PROGRAM = "credit_bonus_program",
}

type HF_tenant_key = "hf_se" | "hf_fi";
interface ISession {
  user_id: string;
  email: string;
  name: string;
  initials: string;
  permissions: string[];
  dealer_id: string;
  feature_flags: FeatureFlagEnum[];
  hf_tenant_key: HF_tenant_key;
  is_onboarded: boolean;
}

interface IAccountDetails {
  user_id: string;
  email: string;
  name: string;
  dealer_id: string;
  legal_entity_id: string;
  state: string;
  created_at: string;
  deleted_at: string | null;
  updated_at: string;
}

interface IUpdateAccountDetails extends IAuthParams {
  name: string;
}

export type { ISession, IAccountDetails, IUpdateAccountDetails };
