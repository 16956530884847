import { useContext } from "react";
import { useGetSessionQuery } from "../../account/service";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { IPermission } from "../types";

const useHasPermissions = (permissions: IPermission[], requireAll: boolean = true) => {
  const accessToken = useContext(AccessTokenContext);
  const { data, isLoading } = useGetSessionQuery({ access_token: accessToken });

  const userPermissions = data?.permissions || [];

  let hasPermission: boolean;

  if (requireAll) {
    hasPermission = permissions.every((permission) => userPermissions.includes(permission));
  } else {
    hasPermission = permissions.some((permission) => userPermissions.includes(permission));
  }

  return [isLoading, hasPermission];
};

export default useHasPermissions;
