import { DealerRequiredAction } from "../../types";
import DefaultTemplate from "../DefaultTemplate";
import CreditLimitExceeded_v1_0 from "./CreditLimitExceeded_v1_0";

const CreditLimitExceeded = (action: DealerRequiredAction) => {
  switch (action.schema_version) {
    case "1.0":
      return CreditLimitExceeded_v1_0;
  }
  return DefaultTemplate;
};

export default CreditLimitExceeded;
