const themeColors = {
  orange: {
    900: "#B53214",
    800: "#CF3916",
    700: "#E84019", //main
    400: "#F2CBC2",
    300: "#F7DBD5",
    200: "#FDECE8",
    100: "#FEF5F3",
  },
  orangeMain: {
    500: "#E84019",
  },
  purple: {
    900: "#2E0822",
    800: "#440C32", //main
    700: "#5E1145",
    600: "#781558",
    500: "#D6BCD6",
    400: "#EDCCE4",
    300: "#F2DFED",
    200: "#ECE4E7",
    100: "#F4F0F3",
  },
  purpleMain: {
    500: "#440C32",
  },
  greens: {
    900: "#168C1A",
    800: "#1AA61F",
    700: "#1EBE23", //main
    400: "#92E394",
    100: "#EDFDEE",
  },
  greenMain: {
    500: "#1EBE23",
  },
  reds: {
    900: "#BD003F",
    800: "#D60047",
    700: "#F00050", //main
    100: "#FFEFF4",
  },
  redMain: {
    500: "#F00050",
  },
  disabled: {
    500: "rgba(197, 197, 197, .1)",
  },
  purpleMenu: {
    500: "rgba(214, 188, 218, .08)",
  },
  overlay: {
    500: "rgba(26, 19, 24, .56)",
  },
  gray: {
    600: "#383838", //dark
    500: "#7B7B7B", //mid
    400: "#C5C5C5", //regular
    300: "#EBEBEB", //light
    200: "#F6F5EE", //off-white
    100: "#FFFFFF", //white
  },
  grayDark: {
    500: "#383838",
  },
  grayMid: {
    500: "#7B7B7B",
  },
  grayRegular: {
    500: "#C5C5C5",
  },
  grayLight: {
    500: "#EBEBEB",
  },
  grayOffWhite: {
    500: "#F6F5EE",
  },
  grayWashed: {
    500: "#FCFCFC",
  },
};

export default themeColors;
