const switchToggle = {
  parts: ["container", "thumb", "track"],
  baseStyle: {
    track: {
      bg: "gray.400",
      _checked: {
        bg: "rgba(30, 190, 35, 0.16)"
      }
    },
    thumb: {
      bg: "white",
      _checked: {
        bg: "greens.700"
      }
    }
  }
};

export default switchToggle;