const accordion = {
  parts: ["container", "track", "thumb", "filledTrack", "mark"],
  baseStyle: {
    track: {
      bg: "orange.200",
      height: "2",
      rounded: "base",
    },
    filledTrack: {
      bg: "orange.700",
    },
    thumb: {
      w: "6",
      h: "6",
      border: "6px solid",
      borderColor: "orange.700",
    },
  },
  // variants: {
  // },
};

export default accordion;
