import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IGetRequiredActionsResponse, IGetRequiredActionsPayload } from "./types";

export const requiredActionsApi = createApi({
  reducerPath: "requiredActionsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["RequiredActions"],
  endpoints: (builder) => ({
    fetchRequiredActions: builder.query<IGetRequiredActionsResponse, IGetRequiredActionsPayload>({
      query: (payload) => {
        const { access_token } = payload;
        return {
          url: "/v1/required_action",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["RequiredActions"],
    }),
  }),
});

export const { useFetchRequiredActionsQuery } = requiredActionsApi;
