import React from "react";

import {BASE_URL} from "../config";

type Props = {
    children: JSX.Element;
};

const AccessTokenContext = React.createContext("");

const LocallyProtectedComponent = ({children}: Props) => {

    const accessToken = localStorage.getItem("ca_local_auth_access_token") || ""
    if (accessToken === "") {
        window.location.href = BASE_URL
    }

    return (
        <>
            <AccessTokenContext.Provider value={accessToken}>
                {children}
            </AccessTokenContext.Provider>
        </>
    );
};

export {AccessTokenContext};
export default LocallyProtectedComponent;
