const table = {
  parts: ["table", "thead", "th", "tr", "td", "caption", "tfoot", "tbody", ],
  baseStyle: {
    table: {
      bg: "#fff",
    },
    thead: {
      bg: "purple.200",
      color: "purple.800",
      tr: {
        _hover: {
          bg: "purple.200",
          color: "purple.800",
        },
      }
    },
    th: {
      fontFamily: "body",
      fontWeight: "normal",
      textTransform: "none",
      letterSpacing: "0",
    },
    tr: {
      transition: "all 120ms ease-in-out",
      _hover: {
        bg: "purple.100",
      },
    },
  },
};

export default table;