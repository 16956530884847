const accordion = {
  parts: ["root", "container", "button", "panel", "icon"],
  baseStyle: {
    button: {
      bg: "purple.800",
      color: "#fff",
      px: { base: 2, lg: 4 },
      pr: { base: 2, lg: 2 },
      py: 2,
      border: "0",
      fontWeight: "medium",
      borderBottom: "1px",
      borderColor: "purple.200",
      _hover: {
        bg: "purple.900",
      },
    },
    container: {
      p: "0",
      border: 0,
    },
    panel: {
      bg: "#fff",
      p: "0",
      border: "1px",
      borderColor: "purple.200",
      borderTop: "0",
    },
  },
  variants: {
    sidebarMenuAccordion: {
      root: {
        mb: 2,
      },
      container: {
        p: 0,
        border: 0,
        bg: "transparent",
      },
      button: {
        borderBottom: "0",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "normal",
        justifyContent: "flexStart",
        textAlign: "left",
        px: 3,
        py: 4,
        minHeight: "56px",
        height: "auto",
        borderRadius: "10px",
        flexWrap: "wrap",
        bg: "transparent",
        w: "100%",
        _hover: {
          bg: "purple.700",
        },
        _active: {
          bg: "purple.900",
        },
        _expanded: {
          bg: "purple.900",
          borderBottomRadius: "0",
        },
      },
      panel: {
        m: 0,
        padding: "0 12px 12px 12px",
        borderRadius: "10px",
        borderTopRadius: 0,
        bg: "purple.900",
        border: "0",
      },
    },
    simple: {
      button: {
        justifyContent: "space-between",
        px: "0",
        borderBottom: "0",
        fontSize: "xs",
        lineHeight: "xs",
        fontWeight: "normal",
        color: "gray.500",
        bgColor: "#fff",
        _hover: {
          color: "purple.800",
          bgColor: "#fff",
        },
        _active: {
          color: "purple.900",
        },
      },
      icon: {
        fontSize: "xl",
      },
      panel: {
        border: "0",
        padding: "0",
      },
    },
    tableView: {
      button: {
        position: "relative",
        w: "full",
        bg: "#fff",
        color: "gray.600",
        px: 4,
        py: 2,
        pr: { base: 4, lg: 4 },
        border: "0",
        fontWeight: "normal",
        borderBottom: "1px",
        borderColor: "gray.300",
        textAlign: "left",
        _hover: {
          bg: "purple.100",
        },
        _expanded: {
          bg: "purple.800",
          color: "#fff",
          fontWeight: "medium",
          _hover: {
            bg: "purple.900",
          },
        },
      },
      icon: {
        position: "absolute",
        right: "3",
        top: "2.5",
      },
    },
  },
};

export default accordion;
