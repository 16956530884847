import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateNotePayload,
  ICreateNoteResponse,
  IFetchNotesPayload,
  IUpdateNotePayload,
  IUpdateNoteResponse,
  IDeleteNotePayload,
  IDeleteNoteResponse,
  IFetchNotesResponse,
} from "./types";

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Notes"],
  endpoints: (builder) => ({
    getNotes: builder.query<IFetchNotesResponse, IFetchNotesPayload>({
      query: (payload) => {
        const { access_token, dealId, ...queryParams } = payload;
        return {
          url: `/v1/deal/${dealId}/note`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Notes"],
    }),
    addNote: builder.mutation<ICreateNoteResponse, ICreateNotePayload>({
      query: ({ access_token, dealId, ...body }) => {
        return {
          url: `/v1/deal/${dealId}/note`,
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Notes"],
    }),
    updateNote: builder.mutation<IUpdateNoteResponse, IUpdateNotePayload>({
      query: ({ access_token, dealId, note_id, content }) => {
        return {
          url: `/v1/deal/${dealId}/note/${note_id}`,
          method: "PUT",
          body: { content },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Notes"],
    }),
    deleteNote: builder.mutation<IDeleteNoteResponse, IDeleteNotePayload>({
      query: ({ access_token, dealId, note_id }) => {
        return {
          url: `/v1/deal/${dealId}/note/${note_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Notes"],
    }),
  }),
});

export const { useGetNotesQuery, useAddNoteMutation, useUpdateNoteMutation, useDeleteNoteMutation } = notesApi;
