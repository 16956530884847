import { IAuthParams } from "../auth/types";

export enum ExternalUserOnboardingState {
  USER_ID_VERIFICATION = "user_id_verification",
  LEGAL_ENTITY_REQUIRED = "legal_entity_required",
  APPLICATION_REQUIRED = "application_required",
  APPLICATION_IN_PROGRESS = "application_in_progress",
  APPLICATION_SUBMITTED = "application_submitted",
  APPLICATION_REJECTED = "application_rejected",
  ONBOARDED = "onboarded",
}
export enum VerificationType {
  MANUAL = "manual",
  BANKID = "bankid",
}

export enum VerificationStatus {
  VERIFICATION_MISSING = "verification_missing",
  VERIFICATION_PENDING = "verification_pending",
  VERIFIED = "verified",
  REJECTED = "rejected",
}

interface IApplicantInfo {
  name?: string;
  phone_no?: string;
}

interface ICompany {
  org_no: string;
  name: string;
}

interface IGetOnboardingStatePayload extends IAuthParams {}

interface IGetOnboardingStateResponse {
  state: ExternalUserOnboardingState;
  kyc_application_id?: string;
}

interface IGetOnboardingOrgsPayload extends IAuthParams {}

interface IGetOnboardingOrgsResponse {
  companies: ICompany[];
}

// TODO: check the payload types from BE
interface ICreateOwnLegalEntityPayload extends IAuthParams {}

interface ICreateOwnLegalEntityResponse {
  legal_entity_id: string;
}

interface ICreateAccountLegalEntityPayload extends IAuthParams {
  org_no: string;
}

interface ICreateAccountLegalEntityResponse {
  legal_entity_id: string;
}

interface IFetchUserIdentityVerificationPayload extends IAuthParams {
  verification_type?: VerificationType;
}

interface IFetchUserIdentityVerificationResponse {
  process_id: string;
  status: VerificationStatus;
  verification_key: string;
  verification_type: VerificationType;
}
interface ICreateUserIdentityVerificationPayload extends IAuthParams {
  verification_type: VerificationType;
  parameters?: {};
}

interface ICreateUserIdentityVerificationResponse {
  process_id: string;
  verification_type: VerificationType;
  parameters: {};
}

export type {
  ICreateUserIdentityVerificationResponse,
  ICreateUserIdentityVerificationPayload,
  IFetchUserIdentityVerificationResponse,
  IFetchUserIdentityVerificationPayload,
  IApplicantInfo,
  IGetOnboardingStatePayload,
  IGetOnboardingStateResponse,
  IGetOnboardingOrgsPayload,
  IGetOnboardingOrgsResponse,
  ICreateOwnLegalEntityPayload,
  ICreateOwnLegalEntityResponse,
  ICreateAccountLegalEntityPayload,
  ICreateAccountLegalEntityResponse,
};
