import { Box, Center, Flex } from "@chakra-ui/react";
import useTranslate from "../../locale/hooks/useTranslate";
import Icon from "../../shared/Icon";
import { DealerRequiredAction } from "../types";

type Props = {
  action: DealerRequiredAction;
};

const DefaultTemplate = ({ action }: Props) => {
  const translate = useTranslate();

  return (
    <Flex gap="2" align="center">
      <Center borderRadius="full" bgColor="reds.100" p="2" flex="0 0 auto" minW="0">
        <Icon name="priority high" color="reds.700" size="32px" />
      </Center>

      <Box fontWeight="medium">{translate(`required_actions.action_types.${action.action_type}`)}</Box>
    </Flex>
  );
};

export default DefaultTemplate;
