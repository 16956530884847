import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useTranslate from "../locale/hooks/useTranslate";
import { RemoteControlContext } from "../shared/RemoteControlProvider";
import RequiredActions from "./RequiredActions";

type Props = {
  isVisible: boolean;
  toggleModal: (value: boolean) => void;
};

export default function RequiredActionsModal({ isVisible, toggleModal }: Props) {
  const translate = useTranslate();
  const location = useLocation();
  //close modal when navigating to same page
  useEffect(() => {
    if (isVisible) {
      toggleModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <Modal isOpen={isVisible} onClose={() => toggleModal(false)} isCentered size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <RequiredActions showWarning />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal(false)} variant="ghost">
            {translate("placeholders.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export const RemoteControlRequiredActionsModal = () => {
  const { elementState, setElementState } = useContext(RemoteControlContext);
  const isVisible = elementState?.["required_actions_modal"] || false;
  const closeModal = () => {
    setElementState({ required_actions_modal: false });
  };
  return <RequiredActionsModal isVisible={isVisible} toggleModal={closeModal} />;
};
