const select = {
  parts: ["field", "icon"],
  baseStyle: {
    field: {
    },
  },
  sizes: {
    md: {
      field: {
        h: "40px",
        borderRadius: "0",
        padding: "0 12px",
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: "#fff",
        borderColor: "grayLight.500",
        _hover: {
          borderColor: "purple.500",
        },
        _focus: {
          borderColor: "purple.600",
          outline: "none",
        },
        _focusVisible: {
          borderColor: "purple.600",
          outline: "none",
          boxShadow: "none",
        },
        _disabled: {
          color: "grayMid.500",
          opacity: 1,
          bg: "disabled.500",
          borderColor: "grayLight.500",
          _hover: {
            color: "grayMid.500",
            opacity: 1,
            bg: "disabled.500",
            borderColor: "grayLight.500",
          },
        },
      },
    },
  },
};

export default select