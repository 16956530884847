import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import Icon from "./Icon";
import useTranslate from "../locale/hooks/useTranslate";

type Props = {
  message?: string;
  icon?: string;
};

const NoItems = ({ message, icon = "sticky_note_2" }: Props) => {
  const translate = useTranslate();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minH="40"
      w="full"
    >
      <Icon name={icon} mb="2" size="48px" color="grayMid.500" />
      <Text color="grayMid.500">
        {message ? message : translate("no_items")}
      </Text>
    </Flex>
  );
};

export default NoItems;
