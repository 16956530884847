import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { accountApi } from "./account/service";
import { contactApi } from "./contacts/service";
import { dealsApi } from "./deals/service";
import { filesApi } from "./files/service";
import { vehicleApi } from "./vehicle/service";
import { dealerAccountApi } from "./dealerAccounts/service";
import { zimplerApi } from "./integrations/zimpler/service";
import { domainEntitySearchApi } from "./domain-entity/domain-entity-search/service";
import { KYCApi } from "./kyc/service";
import { requiredActionsApi } from "./requiredActions/service";
import { authApi } from "./auth/service";
import { transactionsApi } from "./transactions/service";
import { dashboardApi } from "./dashboard/service";
import { inventoryVehicleApi } from "./inventory/service";
import { domainEventsApi } from "./domain-events/service";
import { upcomingEventsApi } from "./upcomingEvents/service";
import { localAuthApi } from "./auth/service";
import { onboardingApi } from "./onboarding/service";
import { loansApi } from "./loans/service";
import { systemMessagesApi } from "./systemMessages/service";
import { externalUsersApi } from "./usersManagement/service";
import { notesApi } from "./notes/service";

export const store = configureStore({
  reducer: {
    [dealsApi.reducerPath]: dealsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [dealerAccountApi.reducerPath]: dealerAccountApi.reducer,
    [zimplerApi.reducerPath]: zimplerApi.reducer,
    [domainEntitySearchApi.reducerPath]: domainEntitySearchApi.reducer,
    [KYCApi.reducerPath]: KYCApi.reducer,
    [requiredActionsApi.reducerPath]: requiredActionsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [inventoryVehicleApi.reducerPath]: inventoryVehicleApi.reducer,
    [domainEventsApi.reducerPath]: domainEventsApi.reducer,
    [upcomingEventsApi.reducerPath]: upcomingEventsApi.reducer,
    [localAuthApi.reducerPath]: localAuthApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [loansApi.reducerPath]: loansApi.reducer,
    [systemMessagesApi.reducerPath]: systemMessagesApi.reducer,
    [externalUsersApi.reducerPath]: externalUsersApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dealsApi.middleware,
      filesApi.middleware,
      vehicleApi.middleware,
      accountApi.middleware,
      contactApi.middleware,
      dealerAccountApi.middleware,
      zimplerApi.middleware,
      domainEntitySearchApi.middleware,
      KYCApi.middleware,
      requiredActionsApi.middleware,
      authApi.middleware,
      transactionsApi.middleware,
      dashboardApi.middleware,
      inventoryVehicleApi.middleware,
      domainEventsApi.middleware,
      upcomingEventsApi.middleware,
      localAuthApi.middleware,
      onboardingApi.middleware,
      loansApi.middleware,
      systemMessagesApi.middleware,
      externalUsersApi.middleware,
      notesApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
