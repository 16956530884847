import { Center } from "@chakra-ui/react";
import React from "react";
import Loader from "./Loader";

const PageLoader = () => {
  return (
    <Center height="100%">
      <Loader />
    </Center>
  );
};

export default PageLoader;
