import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IGetSystemMessagesPayload, IGetSystemMessagesResponse } from "./types";

export const systemMessagesApi = createApi({
  reducerPath: "systemMessagesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["SystemMessages"],
  endpoints: (builder) => ({
    getSystemMessages: builder.query<IGetSystemMessagesResponse, IGetSystemMessagesPayload>({
      query: ({ access_token }) => {
        return {
          url: "v1/system_messages",
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["SystemMessages"],
    }),
  }),
});

export const { useGetSystemMessagesQuery } = systemMessagesApi;
