import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateExternalUserPayload,
  ICreateExternalUserResponse,
  IFetchExternalUsersPayload,
  IFetchExternalUsersResponse,
  ISendExternalUserInvitePayload,
  ISendExternalUserInviteResponse,
  IFetchExternalUserPayload,
  IFetchExternalUserResponse,
  ISetExternalUserRolesResponse,
  IFetchExternalUserRolesPayload,
  IFetchExternalUserRolesResponse,
  IFetchActiveExternalUserRolesPayload,
  IFetchActiveExternalUserRolesResponse,
  IRemoveExternalUserResponse,
  IRemoveExternalUserPayload,
  ISetExternalUserRolesPayload,
  IBatchLookupUsersPayload,
  IBatchLookupUsersResponse,
} from "./types";

export const externalUsersApi = createApi({
  reducerPath: "externalUsersApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["ExternalUsers", "ExternalUser", "UserRoles"],
  endpoints: builder => ({
    fetchExternalUsers: builder.query<IFetchExternalUsersResponse, IFetchExternalUsersPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/user",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["ExternalUsers"],
    }),
    createExternalUser: builder.mutation<ICreateExternalUserResponse, ICreateExternalUserPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: "/v1/user",
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    sendExternalUserInvite: builder.mutation<ISendExternalUserInviteResponse, ISendExternalUserInvitePayload>({
      query: ({ access_token, user_id }) => {
        return {
          url: `/v1/user/${user_id}/send_invite`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    fetchExternalUser: builder.query<IFetchExternalUserResponse, IFetchExternalUserPayload>({
      query: ({ access_token, user_id }) => {
        return {
          url: `/v1/user/${user_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["ExternalUser"],
    }),
    setExternalUserRoles: builder.mutation<ISetExternalUserRolesResponse, ISetExternalUserRolesPayload>({
      query: ({ access_token, user_id, role_keys }) => {
        return {
          url: `/v1/user/${user_id}/set_roles`,
          method: "PUT",
          body: { role_keys },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["UserRoles"],
    }),
    fetchExternalUserRoles: builder.query<IFetchExternalUserRolesResponse, IFetchExternalUserRolesPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/user_role",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["UserRoles"],
    }),
    fetchActiveExternalUserRoles: builder.query<
      IFetchActiveExternalUserRolesResponse,
      IFetchActiveExternalUserRolesPayload
    >({
      query: ({ access_token, user_id, ...queryParams }) => {
        return {
          url: `/v1/user/${user_id}/user_role`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["UserRoles"],
    }),
    removeExternalUser: builder.mutation<IRemoveExternalUserResponse, IRemoveExternalUserPayload>({
      query: ({ access_token, user_id }) => {
        return {
          url: `/v1/user/${user_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    batchLookupUsers: builder.mutation<IBatchLookupUsersResponse, IBatchLookupUsersPayload>({
      query: ({ access_token, user_ids }) => {
        return {
          url: "/v1/user_batch_lookup",
          method: "POST",
          body: { user_ids },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchExternalUsersQuery,
  useCreateExternalUserMutation,
  useSendExternalUserInviteMutation,
  useFetchExternalUserQuery,
  useSetExternalUserRolesMutation,
  useFetchExternalUserRolesQuery,
  useFetchActiveExternalUserRolesQuery,
  useRemoveExternalUserMutation,
  useBatchLookupUsersMutation,
} = externalUsersApi;
