interface IAuthParams {
  access_token?: string;
}
export enum IBankIdAuthStatus {
  COMPLETE = "complete",
  PENDING = "pending",
  FAILED = "failed",
}

interface InitializeBankIdAuthPayload extends IAuthParams {}

interface InitializeBankIdAuthResponse {
  order_id: string;
  token: string;
  qr_data: string;
}

interface IFetchBankIdAuthStatusPayload extends IAuthParams {
  order_id: string;
}

type BankIdHintForPendingOrders =
  | "outstanding_transaction"
  | "no_client"
  | "started"
  | "user_mrtd"
  | "user_call_confirm"
  | "user_sign"
  | "unknown_hint_code";
interface IFetchBankIdAuthStatusResponse {
  eid_token?: string;
  name?: string;
  qr_data: string;
  ssn?: string;
  hint?: BankIdHintForPendingOrders;
  status: IBankIdAuthStatus;
}

type AuthorizedActions = "create_dealer_account" | "create_deal";
type AuthorizationRequirementType = "valid_dealer_kyc" | "bankid_verification";

interface IGetActionAuthorizationPayload extends IAuthParams {
  action: AuthorizedActions;
}

interface IAuthorizationRequirement {
  type: AuthorizationRequirementType;
  is_fulfilled: boolean;
}
interface IGetActionAuthorizationResponse {
  requirements: IAuthorizationRequirement[];
}
interface IPostTokenPayload {
  code: string;
  grant_type: string;
  code_verifier: string;
}

export type {
  IAuthParams,
  InitializeBankIdAuthPayload,
  InitializeBankIdAuthResponse,
  IFetchBankIdAuthStatusPayload,
  IFetchBankIdAuthStatusResponse,
  IGetActionAuthorizationPayload,
  IGetActionAuthorizationResponse,
  AuthorizedActions,
  AuthorizationRequirementType,
  IAuthorizationRequirement,
  BankIdHintForPendingOrders,
  IPostTokenPayload,
};
