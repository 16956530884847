import { DealerRequiredAction } from "../../types";
import DefaultTemplate from "../DefaultTemplate";
import OwnershipTransferOutstanding_v1_0 from "./OwnershipTransferOutstanding_v1_0";

const OwnershipTransferOutstanding = (action: DealerRequiredAction) => {
  switch (action.schema_version) {
    case "1.0":
      return OwnershipTransferOutstanding_v1_0;
  }
  return DefaultTemplate;
};

export default OwnershipTransferOutstanding;
