import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IGenerateDepositUrlPayload } from "./types";

export const zimplerApi = createApi({
  reducerPath: "zimplerApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    generateDepositUrl: builder.mutation<
      { url: string },
      IGenerateDepositUrlPayload
    >({
      query: (payload) => {
        const { access_token, deal_id, ...body } = payload;
        return {
          url: `/v1/deal/${deal_id}/deposit_url`,
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
  }),
});

export const { useGenerateDepositUrlMutation } = zimplerApi;
