import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IVehicleResponse,
  IVehicleFetchPayload,
  IImportVehiclePayload,
  ICreateVehicleResponse,
  ICreateVehiclePayload,
  IEditVehicleResponse,
  IEditVehiclePayload,
} from "./types";

export const vehicleApi = createApi({
  reducerPath: "vehicleApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Vehicle", "Valuation"],
  endpoints: (builder) => ({
    getVehicle: builder.query<IVehicleResponse, IVehicleFetchPayload>({
      query: (payload) => {
        const { access_token, vehicle_id } = payload;
        return {
          url: `/v1/vehicle/${vehicle_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Vehicle"],
    }),
    importVehicle: builder.mutation<IVehicleResponse, IImportVehiclePayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/vehicle_import`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    // TODO: "Update the url to create a vehicle and double check if the method is correct",
    createVehicle: builder.mutation<ICreateVehicleResponse, ICreateVehiclePayload>({
      query: ({ access_token, payload }) => {
        return {
          url: `/v1/vehicle`,
          method: "POST",
          body: { vehicle: payload },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    // TODO: "Update the url to create a vehicle and double check if the method is correct",
    editVehicle: builder.mutation<IEditVehicleResponse, IEditVehiclePayload>({
      query: (payload) => {
        const { access_token, vehicle_id, revision_id, ...vehicle } = payload;
        return {
          url: `/v1/vehicle/${vehicle_id}`,
          method: "PATCH",
          body: {
            revision_id,
            ...vehicle,
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
  }),
});

export const { useGetVehicleQuery, useImportVehicleMutation, useCreateVehicleMutation, useEditVehicleMutation } =
  vehicleApi;
