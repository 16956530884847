import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import useUrlState from "../../hooks/useUrlState";
import {useFetchTokenMutation} from "../service";

const LocalAuthCallbackPage = () => {
  const navigate = useNavigate();
  const [urlState] = useUrlState({});

  const hasFetchedTokenRef = React.useRef(false);
  const [fetchToken] = useFetchTokenMutation()
  const code = urlState.code;



  useEffect(() => {
    const setAccessTokenLocalStorage = (accessToken: string) => {
      localStorage.setItem("ca_local_auth_access_token", accessToken);
    }
    const getAccessToken = async () => {
      try {
        hasFetchedTokenRef.current = true;
        const response = await fetchToken({code:code,  grant_type:'authorization_code', code_verifier:'dummy'}) as any
        setAccessTokenLocalStorage(response.data.access_token)
        navigate(`/dashboard`);
      } catch (e) {
        console.log("error", e)
      }
    };
    if (code && !hasFetchedTokenRef.current){
      getAccessToken();
    }
  }, [fetchToken, navigate, code]);



  return null;
};

export default LocalAuthCallbackPage;
