const radio = {
  parts: ["container", "control"],
  baseStyle: {
    control: {
      w: 4,
      h: 4,
      borderRadius: "full",
      bg: "#fff",
      borderColor: "purple.200",
      _checked: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _indeterminate: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
    },
  },
  variants: {
    radioSelect: {
      container: {
        flex: "1 1 40%",
        p: 2,
        border: "1px",
        borderColor: "grayLight.500",
        cursor: "pointer",
        _checked: {
          borderColor: "grayLight.500",
          cursor: "pointer",
          bg: "purple.100",
          color: "purple.800",
        },
      },
    },
    rounded: {
      container: {
        p: 2,
        border: "1px",
        borderColor: "grayLight.500",
        borderRadius: "lg",
        cursor: "pointer",
        _checked: {
          cursor: "pointer",
          borderColor: "purple.800",
          bg: "purple.200",
          color: "purple.900",
        },
      },
    },
    rounded_with_bg: {
      container: {
        p: 2,
        border: "1px",
        borderColor: "grayLight.500",
        borderRadius: "lg",
        bg: "white",
        cursor: "pointer",
        _checked: {
          cursor: "pointer",
          borderColor: "purple.800",
          bg: "purple.200",
          color: "purple.900",
        },
      },
    },
  },
};

export default radio;
