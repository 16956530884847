import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetSessionQuery } from "../account/service";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import { useGetLastKycApplication } from "../kyc/hooks/useGetKYCPendingApplication";
import PageLoader from "./PageLoader";

interface Props {
  children: React.ReactNode;
}

export default function AccessManager({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const access_token = useContext(AccessTokenContext);
  const { data, isLoading: fetchingSession } = useGetSessionQuery(
    { access_token },
    { refetchOnMountOrArgChange: true }
  );
  const { kycApplicationId, kycApplicationStatus, isLoading } = useGetLastKycApplication();

  useEffect(() => {
    if (isLoading || fetchingSession) return;
    if (!data?.is_onboarded) {
      navigate("/onboarding");
      return;
    }
    if (location.pathname.startsWith("/my-account")) return;
  }, [kycApplicationId, kycApplicationStatus, isLoading, data, fetchingSession, navigate, location.pathname]);

  if (fetchingSession) {
    return <PageLoader />;
  }
  return <>{children}</>;
}
