const tag = {
  parts: ["container", "label", "closeButton"],
  baseStyle: {
    container: {
      borderRadius: "20px",
      h: 8,
      paddingInlineStart: 4,
      paddingInlineEnd: 4,
    },
  },
  variants: {
    primary: {
      container: {
        bgColor: "purple.200",
        color: "purple.700",
      },
    },
    primaryActive: {
      container: {
        bgColor: "purple.800",
        color: "white",
      },
    },
  },
};

export default tag;
