import { DealerRequiredAction } from "../../types";
import KycRequired_v1_0 from "./KycRequired_v1_0";
import DefaultTemplate from "../DefaultTemplate";

const KycRequired = (action: DealerRequiredAction) => {
  switch (action.schema_version) {
    case "1.0":
      return KycRequired_v1_0;
  }
  return DefaultTemplate;
};

export default KycRequired;
