import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateApplicationPayload,
  ICreateApplicationResponse,
  IGetKYCApplicationPayload,
  IGetKYCApplicationResponse,
  IListKYCApplicationPayload,
  IListKYCApplicationResponse,
  IFetchBoardMembersPayload,
  IFetchBeneficialOwnersPayload,
  FetchBeneficialOwnersResponse,
  FetchBoardMembersResponse,
  ISubmitApplicationPayload,
  ISubmitApplicationResponse,
  IUpdateKYCApplicationPayload,
  IUpdateKYCApplicationResponse,
} from "./types";

export const KYCApi = createApi({
  reducerPath: "KYCApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["KYCApplication"],
  endpoints: builder => ({
    listApplications: builder.query<IListKYCApplicationResponse, IListKYCApplicationPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/kyc/application",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["KYCApplication"],
    }),
    getApplication: builder.query<IGetKYCApplicationResponse, IGetKYCApplicationPayload>({
      query: ({ access_token, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["KYCApplication"],
    }),
    updateKYCApplication: builder.mutation<IUpdateKYCApplicationResponse, IUpdateKYCApplicationPayload>({
      query: ({ access_token, kyc_application_id, ...payload }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["KYCApplication"],
    }),
    createApplication: builder.mutation<ICreateApplicationResponse, ICreateApplicationPayload>({
      query: ({ access_token, ...payload }) => {
        return {
          url: `/v1/kyc/application`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["KYCApplication"],
    }),
    submitApplication: builder.mutation<ISubmitApplicationResponse, ISubmitApplicationPayload>({
      query: ({ access_token, kyc_application_id, ...payload }) => {
        return {
          url: `v1/kyc/application/${kyc_application_id}/submit`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["KYCApplication"],
    }),
    fetchBoardMembers: builder.query<FetchBoardMembersResponse, IFetchBoardMembersPayload>({
      query: ({ access_token, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/board_members`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["KYCApplication"],
    }),
    fetchBeneficialOwners: builder.query<FetchBeneficialOwnersResponse, IFetchBeneficialOwnersPayload>({
      query: ({ access_token, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/beneficial_owners`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["KYCApplication"],
    }),
  }),
});

export const {
  useGetApplicationQuery,
  useCreateApplicationMutation,
  useListApplicationsQuery,
  useFetchBoardMembersQuery,
  useFetchBeneficialOwnersQuery,
  useSubmitApplicationMutation,
  useUpdateKYCApplicationMutation,
} = KYCApi;
