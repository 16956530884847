import {translations as en_translations} from "./en";
import {translations as sv_translations} from "./sv";
import {translations as fi_translations} from "./fi";

export const resources = {
    en: {translation: en_translations},
    sv: {translation: sv_translations},
    fi: {translation: fi_translations},
};

