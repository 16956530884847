const form = {
  parts: ["container"],
  baseStyle: {
    container: {
      label: {
        color: "grayMid.500",
        fontWeight: "normal",
        fontSize: "xs",
        lineHeight: "xs",
        textTransform: "uppercase",
        mb: 2,
      },
    }
  },
}

export default form;